<template>
    <div>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader type="heading, table-row@3, button"></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-card v-if="!loading">
            <v-card-text>
                <form>
                    <v-row>
                        <v-col cols="12" xl="9">
                            <v-row>
                                <v-col cols="auto" v-if="form.id">
                                    <v-icon
                                        v-if="form.inFavorites"
                                        @click="removeFromFavorites(form)"
                                        color="yellow"
                                        class="ml-1">
                                        mdi-star
                                    </v-icon>
                                    <v-icon
                                        v-if="!form.inFavorites"
                                        @click="addToFavorites(form)"
                                        color="grey"
                                        class="ml-1">
                                        mdi-star-outline
                                    </v-icon>
                                </v-col>
                                <v-col v-if="form.id">
                                    <v-text-field :value="form.id" disabled="disabled" label="ID"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-autocomplete
                                        v-model="form.state"
                                        :items="states"
                                        :disabled="
                                                (!isManualDrivenState(form.state) && !$helper.permission.canChangeContractNotManualState(user, form.state))
                                                || !formFields.state.isEnabled(user)
                                            "
                                        v-on:change="onChangeState"
                                        label="Статус">
                                    </v-autocomplete>
                                    <v-alert v-if="form.errors.has('state')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('state') }}
                                    </v-alert>
                                </v-col>
                                <v-col>
                                    <v-autocomplete
                                        v-model="form.phase"
                                        :items="phases"
                                        label="Этап исполнения">
                                    </v-autocomplete>
                                    <v-alert v-if="form.errors.has('phase')" dense outlined type="error" :icon="false">
                                        {{ form.errors.get('phase') }}
                                    </v-alert>
                                </v-col>
                                <v-col v-if="form.id">
                                    <v-text-field
                                        :value="form.createdAt"
                                        disabled="disabled"
                                        label="Дата создания">
                                    </v-text-field>
                                </v-col>
                                <v-col>
                                    <v-dialog
                                        ref="dateDialog"
                                        v-model="dateDialog"
                                        :return-value.sync="form.date"
                                        persistent width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                :disabled="!formFields.date.isEnabled(user)"
                                                v-model="form.date"
                                                label="Дата регистрации"
                                                prepend-icon="mdi-calendar-month-outline"
                                                readonly
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="form.date"
                                            type="date"
                                            scrollable
                                            first-day-of-week="1">
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="dateDialog = false">Отмена</v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.dateDialog.save(form.date)">
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                                    <v-alert v-if="form.errors.has('date')" dense outlined type="error" :icon="false">
                                        {{ form.errors.get('date') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row v-if="contract !== null">
                                <v-col v-if="isFieldVisible('sentForDocumentsPreparationAt')">
                                    <ContractAction
                                        :actions="form.sentForDocumentsActions"
                                        label="Дата отправки на подготовку документов"
                                        @deleteAction="deleteSentForDocumentsActions($event)">
                                    </ContractAction>
                                </v-col>
                                <v-col v-if="isFieldVisible('documentsAreReadyAt')">
                                    <ContractAction
                                        :actions="form.documentsAreReadyActions"
                                        label="Документы готовы"
                                        @deleteAction="deleteDocumentsAreReadyActions($event)">
                                    </ContractAction>
                                </v-col>
                                <v-col v-if="isFieldVisible('sentDosudebkaEPO')">
                                    <ContractAction
                                        :actions="form.sentDosudebkaEPOActions"
                                        label="Досудебка ЭПО"
                                        @deleteAction="deleteSentDosudebkaEPOActions($event)">
                                    </ContractAction>
                                </v-col>
                            </v-row>
                            <v-row v-if="contract !== null">
                                <v-col v-if="isFieldVisible('transferredToCourt')">
                                    <ContractAction
                                        :actions="form.transferredToCourtActions"
                                        label="Передано на подачу в суд"
                                        @deleteAction="deleteTransferredToCourtActions($event)">
                                    </ContractAction>
                                </v-col>
                                <v-col v-if="isFieldVisible('sentToCourt')">
                                    <ContractAction
                                        :actions="form.sentToCourtActions"
                                        label="Подано в суд"
                                        @deleteAction="deleteSentToCourtActions($event)">
                                    </ContractAction>
                                </v-col>
                                <v-col v-if="isFieldVisible('transferredToOGPAAt')">
                                    <ContractAction
                                        :actions="form.transferredToOGPAActions"
                                        label="Передано в ОГПиА"
                                        @deleteAction="deleteTransferredToOGPAActions($event)">
                                    </ContractAction>
                                </v-col>
                            </v-row>
                            <v-row v-if="contract !== null">
                                <v-col v-if="isFieldVisible('additionalPackageIsReady')">
                                    <ContractAction
                                        :actions="form.additionalPackageIsReadyActions"
                                        label="Доп. пакет готов к выдаче"
                                        @deleteAction="deleteAdditionalPackageIsReadyActions($event)">
                                    </ContractAction>
                                </v-col>
                                <v-col
                                    cols="auto"
                                    v-if="isFieldVisible('packageToLenyr')">
                                    <ContractAction
                                        :actions="form.packageFromLenyrActions"
                                        label="Пакет от ЛенЮр"
                                        @deleteAction="deletePackageFromLenyrActions($event)">
                                    </ContractAction>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="form.number"
                                        label="Номер"
                                        :disabled="!formFields.number.isEnabled(user)">
                                    </v-text-field>
                                    <v-alert v-if="form.errors.has('number')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('number') }}
                                    </v-alert>
                                </v-col>
                                <v-col v-if="isFieldVisible('type')">
                                    <v-select
                                        v-model="form.type"
                                        :items="types"
                                        label="Вид"
                                        :clearable="true"
                                        :disabled="!formFields.type.isEnabled(user)">
                                    </v-select>
                                    <v-alert v-if="form.errors.has('type')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('type') }}
                                    </v-alert>
                                </v-col>
                                <v-col>
                                    <v-select
                                        v-model="form.subject"
                                        :items="subjects"
                                        label="Предмет"
                                        :clearable="true"
                                        :disabled="!formFields.subject.isEnabled(user)">
                                    </v-select>
                                    <v-alert v-if="form.errors.has('subject')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('subject') }}
                                    </v-alert>
                                </v-col>
                                <v-col>
                                    <v-datetime-picker
                                        v-model="form.courtDateTime"
                                        type="date"
                                        scrollable
                                        label="Дата суда"
                                        :disabled="!formFields.courtDateTime.isEnabled(user)"
                                        clearText="Удалить"
                                        okText="Сохранить"
                                        :datePickerProps="{'first-day-of-week': '1'}"
                                        :timePickerProps="{'format': '24h'}">
                                    </v-datetime-picker>
                                    <v-alert v-if="form.errors.has('courtDateTime')"
                                             dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('courtDateTime') }}
                                    </v-alert>
                                </v-col>
                                <v-col v-if="isFieldVisible('area')">
                                    <v-autocomplete
                                        v-model="form.area"
                                        :items="areas"
                                        item-value="id"
                                        item-text="name"
                                        label="Область права"
                                        :clearable="true">
                                    </v-autocomplete>
                                    <v-alert v-if="form.errors.has('area')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('area') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="form.amount"
                                        label="Сумма договора"
                                        :disabled="!formFields.amount.isEnabled(user)">
                                    </v-text-field>
                                    <v-alert v-if="form.errors.has('amount')" dense outlined type="error" :icon="false">
                                        {{ form.errors.get('amount') }}
                                    </v-alert>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        :value="form.earnedAmount"
                                        disabled="disabled"
                                        label="Освоенная сумма">
                                    </v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        :value="payedAmount"
                                        disabled="disabled"
                                        label="Остаток cуммы">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col v-if="isFieldVisible('lawyer')">
                                    <v-row>
                                        <v-col>
                                            <v-select
                                                v-model="form.lawyer"
                                                :items="lawyers"
                                                label="Юрист"
                                                item-value="id"
                                                item-text="fullName"
                                                :clearable="true"
                                                :disabled="!formFields.lawyer.isEnabled(user)">
                                            </v-select>
                                            <v-alert v-if="form.errors.has('lawyer')" dense outlined type="error" :icon="false">
                                                {{ form.errors.get('lawyer') }}
                                            </v-alert>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field
                                                v-model="form.lawyerBonus"
                                                type="number"
                                                label="% бонус">
                                            </v-text-field>
                                            <v-alert v-if="form.errors.has('lawyerBonus')" dense outlined type="error" :icon="false">
                                                {{ form.errors.get('lawyerBonus') }}
                                            </v-alert>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col v-if="isFieldVisible('additionalLawyer')">
                                    <v-row>
                                        <v-col>
                                            <v-select
                                                v-model="form.additionalLawyer"
                                                :items="lawyers"
                                                label="Доп. юрист"
                                                item-value="id"
                                                item-text="fullName"
                                                :clearable="true"
                                                :disabled="!formFields.lawyer.isEnabled(user)">
                                            </v-select>
                                            <v-alert v-if="form.errors.has('additionalLawyer')" dense outlined type="error" :icon="false">
                                                {{ form.errors.get('additionalLawyer') }}
                                            </v-alert>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field
                                                v-model="form.additionalLawyerBonus"
                                                type="number"
                                                label="% бонус">
                                            </v-text-field>
                                            <v-alert v-if="form.errors.has('additionalLawyerBonus')" dense outlined type="error" :icon="false">
                                                {{ form.errors.get('additionalLawyerBonus') }}
                                            </v-alert>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col v-if="isFieldVisible('lawyerAssistant')">
                                    <v-select
                                        v-model="form.lawyerAssistant"
                                        :items="lawyers"
                                        label="Помощник юриста"
                                        item-value="id"
                                        item-text="fullName"
                                        :clearable="true"
                                        :disabled="!formFields.lawyer.isEnabled(user)">
                                    </v-select>
                                    <v-alert v-if="form.errors.has('lawyerAssistant')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('lawyerAssistant') }}
                                    </v-alert>
                                </v-col>
                                <v-col cols="auto" v-if="isFieldVisible('isEmployeeControl')">
                                    <v-checkbox
                                        class="chekbox__blue"
                                        v-model="form.isEmployeeControl"
                                        label="Контроль сотрудника">
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col v-if="isFieldVisible('lawyerOGPA')">
                                    <v-select
                                        v-model="form.lawyerOGPA"
                                        :items="lawyersOGPA"
                                        label="Юрист ОГПиА"
                                        item-value="id"
                                        item-text="fullName"
                                        :clearable="true"
                                        :disabled="!formFields.lawyerOGPA.isEnabled(user)">
                                    </v-select>
                                    <v-alert v-if="form.errors.has('lawyerOGPA')" dense outlined type="error" :icon="false">
                                        {{ form.errors.get('lawyerOGPA') }}
                                    </v-alert>
                                </v-col>
                                <v-col v-if="isFieldVisible('lawyerOGPA')">
                                    <v-select
                                        v-model="form.additionalLawyerOGPA"
                                        :items="lawyersOGPA"
                                        label="Доп. юрист ОГПиА"
                                        item-value="id"
                                        item-text="fullName"
                                        :clearable="true"
                                        :disabled="!formFields.lawyerOGPA.isEnabled(user)">
                                    </v-select>
                                    <v-alert
                                        v-if="form.errors.has('additionalLawyerOGPA')"
                                        dense
                                        outlined
                                        type="error"
                                        :icon="false">
                                        {{ form.errors.get('additionalLawyerOGPA') }}
                                    </v-alert>
                                </v-col>
                                <v-col v-if="isFieldVisible('lawyerOGPA')">
                                    <v-select
                                        v-model="form.lawyerOGPAAssistant"
                                        :items="lawyersOGPA"
                                        label="Помощник юриста ОГПиА"
                                        item-value="id"
                                        item-text="fullName"
                                        :clearable="true"
                                        :disabled="!formFields.lawyerOGPA.isEnabled(user)">
                                    </v-select>
                                    <v-alert v-if="form.errors.has('lawyerOGPAAssistant')" dense outlined type="error" :icon="false">
                                        {{ form.errors.get('lawyerOGPAAssistant') }}
                                    </v-alert>
                                </v-col>
                                <v-col cols="auto" v-if="isFieldVisible('isPaymentOGPA')">
                                    <v-checkbox
                                        class="chekbox__blue"
                                        v-model="form.isPaymentOGPA"
                                        label="Нагрузка ОГПиА">
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col v-if="isFieldVisible('lawyerEPO')">
                                    <v-select
                                        v-model="form.lawyerEPO"
                                        :items="lawyersEPO"
                                        label="Юрист ЭПО"
                                        item-value="id"
                                        item-text="fullName"
                                        :clearable="true"
                                        :disabled="!formFields.lawyerEPO.isEnabled(user)">
                                    </v-select>
                                    <v-alert v-if="form.errors.has('lawyerEPO')" dense outlined type="error" :icon="false">
                                        {{ form.errors.get('lawyerEPO') }}
                                    </v-alert>
                                </v-col>
                                <v-col v-if="isFieldVisible('additionalLawyerEPO')">
                                    <v-select
                                        v-model="form.additionalLawyerEPO"
                                        :items="lawyersEPO"
                                        label="Подача документов в суд (юрист ЭПО)"
                                        item-value="id"
                                        item-text="fullName"
                                        :clearable="true"
                                        :disabled="!formFields.lawyerEPO.isEnabled(user)">
                                    </v-select>
                                    <v-alert v-if="form.errors.has('additionalLawyerEPO')" dense outlined type="error" :icon="false">
                                        {{ form.errors.get('additionalLawyerEPO') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="auto">
                                    <v-text-field
                                        v-model="form.userName"
                                        label="Клиент"
                                        :disabled="!formFields.userName.isEnabled(user)">
                                    </v-text-field>
                                    <v-alert v-if="form.errors.has('userName')" dense outlined type="error" :icon="false">
                                        {{ form.errors.get('userName') }}
                                    </v-alert>
                                </v-col>
                                <v-col
                                    cols="auto"
                                    v-if="isFieldVisible('userPhone') && $_.get(page, 'contract.client.userPhone')">
                                    <v-text-field
                                        :value="contract.client.userPhone"
                                        label="Телефон"
                                        disabled>
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="auto"
                                    v-if="isFieldVisible('userPhone') && $_.get(page, 'contract.client.userPhone')">
                                    <a :href="`tel:${contract.client.userPhone}`" style="text-decoration: none">
                                        <v-btn small outlined color="green">
                                            <v-icon left small style="margin: 0">mdi-phone</v-icon>
                                        </v-btn>
                                    </a>
                                </v-col>
                                <v-col
                                    cols="auto"
                                    v-if="isFieldVisible('userPhone')">
                                    <v-text-field
                                        v-model="form.clientPhone"
                                        v-mask="'+7 (###) ###-##-## #'"
                                        label="Телефон">
                                    </v-text-field>
                                    <v-alert v-if="form.errors.has('clientPhone')" dense outlined type="error" :icon="false">
                                        {{ form.errors.get('clientPhone') }}
                                    </v-alert>
                                </v-col>
                                <v-col
                                    cols="auto"
                                    v-if="isFieldVisible('userPhone') && $_.get(page, 'contract.clientPhone')">
                                    <a :href="`tel:${contract.clientPhone}`" style="text-decoration: none">
                                        <v-btn small outlined color="green">
                                            <v-icon left small style="margin: 0">mdi-phone</v-icon>
                                        </v-btn>
                                    </a>
                                </v-col>
                                <v-col
                                    cols="auto"
                                    v-if="isFieldVisible('additionalPhone') && $_.get(page, 'contract.client.additionalPhone')">
                                    <v-text-field
                                        :value="contract.client.additionalPhone"
                                        label="Доп. телефон"
                                        disabled>
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="auto"
                                    v-if="isFieldVisible('additionalPhone') && $_.get(page, 'contract.client.additionalPhone')">
                                    <a :href="`tel:${contract.client.additionalPhone}`" style="text-decoration: none">
                                        <v-btn small outlined color="green">
                                            <v-icon left small style="margin: 0">mdi-phone</v-icon>
                                        </v-btn>
                                    </a>
                                </v-col>
                                <v-col cols="auto">
                                    <v-text-field
                                        v-model="form.clientEmail"
                                        label="Email"
                                        :disabled="!formFields.clientEmail.isEnabled(user)">
                                    </v-text-field>
                                    <v-alert v-if="form.errors.has('clientEmail')" dense outlined type="error" :icon="false">
                                        {{ form.errors.get('clientEmail') }}
                                    </v-alert>
                                </v-col>
                                <v-col
                                    cols="auto"
                                    v-if="isFieldVisible('userEmail') && $_.get(page, 'contract.client.userEmail')">
                                    <v-text-field
                                        :value="contract.client.userEmail"
                                        label="Email"
                                        disabled>
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="auto"
                                    v-if="isFieldVisible('userPhone') && $_.get(page, 'contract.client.id')">
                                    <v-btn
                                        :to="`/clients/${page.contract.client.id}/edit`"
                                        small
                                        outlined
                                        color="green">
                                        Перейти
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="form.principal"
                                        label="Доверитель"
                                        :disabled="!formFields.principal.isEnabled(user)">
                                    </v-text-field>
                                    <v-alert v-if="form.errors.has('principal')" dense outlined type="error" :icon="false">
                                        {{ form.errors.get('principal') }}
                                    </v-alert>
                                </v-col>
                                <v-col>
                                    <v-autocomplete
                                        v-model="form.office"
                                        label="Офис"
                                        :items="offices"
                                        item-value="id"
                                        item-text="name"
                                        :disabled="!$helper.permission.canCreateContract(user)"
                                        :clearable="true">
                                    </v-autocomplete>
                                    <v-alert v-if="form.errors.has('office')" dense outlined type="error" :icon="false">
                                        {{ form.errors.get('office') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <div v-for="(flow, key) in form.contractFlows" :key="flow.id">
                                        <v-row align="center">
                                            <v-col>
                                                <DateField
                                                    :form="form"
                                                    v-model="flow.applicationDate"
                                                    :disabled="Boolean(flow.id)"
                                                    label="Дата поступления заявления"
                                                    fieldPath="applicationDate"
                                                ></DateField>
                                            </v-col>
                                            <v-col>
                                                <DateField
                                                    :form="form"
                                                    v-model="flow.answerDate"
                                                    :disabled="Boolean(flow.id)"
                                                    label="Дата направления ответа"
                                                    fieldPath="answerDate"
                                                ></DateField>
                                            </v-col>
                                            <v-col>
                                                <DateField
                                                    :form="form"
                                                    v-model="flow.moneyBackDate"
                                                    :disabled="Boolean(flow.id)"
                                                    label="Дата возврата денежных средств"
                                                    fieldPath="moneyBackDate"
                                                ></DateField>
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-checkbox
                                                    class="chekbox__white"
                                                    v-model="flow.otherApplication"
                                                    label="Иное заявление">
                                                    >
                                                </v-checkbox>
                                            </v-col>
                                            <v-col cols="1">
                                                <v-btn
                                                    text
                                                    color="success"
                                                    @click="addNewFlow()"
                                                    icon
                                                    v-if="(key + 1) == form.contractFlows.length">
                                                    <v-icon>mdi-plus-box-outline</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="1">
                                                <v-btn
                                                    text
                                                    color="red"
                                                    @click="removeFlow(key)"
                                                    icon
                                                    v-if="!flow.id">
                                                    <v-icon>mdi-minus-box-outline</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="flow.id" class="flow_comment text-">
                                            <v-col cols="auto">
                                                {{ flow.user.fullName }}:
                                            </v-col>
                                            <v-col>
                                                {{ flow.comment }}
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="!flow.id">
                                            <v-col>
                                                <v-textarea v-model="flow.comment"
                                                            label="Комментарий"
                                                            rows="2"
                                                            auto-grow>
                                                </v-textarea>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div v-if="!form.contractFlows || form.contractFlows.length < 1">
                                        <v-btn
                                            text
                                            color="success"
                                            @click="addNewFlow()">
                                            <v-icon>mdi-plus-box-outline</v-icon>
                                            Добавить действие
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col
                                    cols="auto"
                                    v-if="isFieldVisible('sentToOKK')">
                                    <ContractAction
                                        :actions="form.sentToOkkActions"
                                        label="Передано в ОКК"
                                        @deleteAction="deleteSentToOkkActions($event)">
                                    </ContractAction>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col v-if="isFieldVisible('actions')">
                                    <v-textarea v-model="form.actions"
                                                label="Действие по договору"
                                                rows="2"
                                                :disabled="!formFields.actions.isEnabled(user)"
                                                auto-grow>
                                    </v-textarea>
                                    <v-alert
                                        v-if="form.errors.has('actions')"
                                        dense
                                        outlined
                                        type="error"
                                        :icon="false">
                                        {{ form.errors.get('actions') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col v-if="isFieldVisible('comment')">
                                    <v-textarea
                                        v-model="form.comment"
                                        label="Примечание"
                                        rows="2"
                                        :disabled="!formFields.comment.isEnabled(user)"
                                        auto-grow>
                                    </v-textarea>
                                    <v-alert v-if="form.errors.has('comment')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('comment') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col v-if="isFieldVisibleOnlyFor('commentForBuhgalter')">
                                    <v-textarea
                                        v-model="form.commentForBuhgalter"
                                        label="Комментарий (бухгалтер)"
                                        rows="2"
                                        :disabled="!formFields.commentForBuhgalter.isEnabled(user)"
                                        auto-grow>
                                    </v-textarea>
                                    <v-alert
                                        v-if="form.errors.has('commentForBuhgalter')"
                                        dense outlined
                                        type="error"
                                        :icon="false">
                                        {{ form.errors.get('commentForBuhgalter') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" xl="3">
                            <v-row>
                                <v-col v-if="false && isFieldVisibleOnlyFor('buhgalter')">
                                    <v-checkbox
                                        class="chekbox__blue"
                                        v-model="form.isSentForBuhgalter"
                                        :disabled="!formFields.sentForBuhgalter.isEnabled(user)"
                                        label="Бухгалтер"
                                        @change="handleChangeSentForBuhgalter()">
                                    </v-checkbox>
                                </v-col>
                                <v-col v-if="false">
                                    <v-checkbox
                                        class="chekbox__blue"
                                        v-model="form.isBezNal"
                                        :disabled="!formFields.isBezNal.isEnabled(user)"
                                        label="Б/н">
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    v-if="isFieldVisible('files')">
                                    <div class="title mt-2 mb-3">Файлы:</div>
                                    <div>
                                        <v-file-input
                                            v-model="files"
                                            chips
                                            counter
                                            multiple
                                            show-size
                                            label="Добавить файлы"
                                            :rules="fileRules">
                                        </v-file-input>
                                        <v-row align="center">
                                            <v-col>
                                                <v-select
                                                    v-model="fileUploadForm.directory.id"
                                                    :items="fileDirectories"
                                                    item-value="id"
                                                    item-text="name"
                                                    label="Папка">
                                                </v-select>
                                                <v-alert v-if="fileUploadForm.errors.has('directory')" dense outlined type="error" :icon="false">
                                                    {{ fileUploadForm.errors.get('directory') }}
                                                </v-alert>
                                            </v-col>
                                            <v-col
                                                cols="auto"
                                                v-if="page?.contract?.id">
                                                <v-btn
                                                    v-show="files"
                                                    color="green"
                                                    :disabled="fileUploading"
                                                    @click="uploadFiles()">
                                                    Сохранить
                                                    <v-progress-circular
                                                        v-if="fileUploading"
                                                        size="16"
                                                        width="2"
                                                        indeterminate>
                                                    </v-progress-circular>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div class="files mt-3"
                                         v-if="form.files !== undefined && Array.isArray(form.files) && form.files.length">
                                        <v-row no-gutters>
                                            <v-col
                                                class="ma-1"
                                                cols="auto"
                                                v-for="(file) in filesWithoutDirectories" :key="file.id">
                                                <drag
                                                    class="drag"
                                                    :transfer-data="{ fileId: file.id }">
                                                    <v-chip>
                                                        <v-progress-circular
                                                            v-if="fileLoading[file.id]"
                                                            size="16"
                                                            width="2"
                                                            indeterminate>
                                                        </v-progress-circular>
                                                        <v-icon
                                                            right
                                                            class="mr-2"
                                                            color="red"
                                                            v-if="$helper.permission.canSaveContract(user)"
                                                            @click="removeFile(file)">
                                                            mdi-close
                                                        </v-icon>
                                                        <a
                                                            href="javascript:void(0)"
                                                            @click="downloadFile(file)">
                                                            {{ file.name }}
                                                        </a>
                                                    </v-chip>
                                                    <div class="font-size__small">
                                                        {{ $moment(file.createdAt).dateTimeFormat() }}
                                                    </div>
                                                </drag>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div class="fileDirectories mt-2 pt-2">
                                        <div
                                            v-for="(dir) in fileDirectories"
                                            :key="dir.id">
                                            <drop
                                                class="drop"
                                                @drop="(data) => handleFileDirectoryDrop(dir.id, data)">
                                                <div>
                                                    <div class="directory-name"
                                                         @click="handleDirectoryCLick(dir)">
                                                        <v-row
                                                            dense
                                                            align="center">
                                                            <v-col cols="auto">
                                                                <v-chip outlined>
                                                                    <v-icon
                                                                        color="red"
                                                                        v-if="!dir.opened">
                                                                        mdi-folder-outline
                                                                    </v-icon>
                                                                    <v-icon
                                                                        color="red"
                                                                        v-if="dir.opened">
                                                                        mdi-folder-open-outline
                                                                    </v-icon>
                                                                    <span v-html="getDirectorySize(dir)" />
                                                                </v-chip>
                                                            </v-col>
                                                            <v-col cols="auto">
                                                                {{ dir.name }}
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                    <div v-if="dir.opened">
                                                        <div
                                                            class="files"
                                                            v-for="(file) in form.files" :key="file.id">
                                                            <drag
                                                                v-if="file.directory?.id === dir.id"
                                                                class="drag"
                                                                :transfer-data="{ fileId: file.id }">
                                                                <v-chip>
                                                                    <v-progress-circular
                                                                        v-if="fileLoading[file.id]"
                                                                        size="16"
                                                                        width="2"
                                                                        indeterminate>
                                                                    </v-progress-circular>
                                                                    <v-icon
                                                                        right
                                                                        class="mr-2"
                                                                        color="red"
                                                                        v-if="$helper.permission.canSaveContract(user)"
                                                                        @click="removeFile(file)">
                                                                        mdi-close
                                                                    </v-icon>
                                                                    <a
                                                                        href="javascript:void(0)"
                                                                        @click="downloadFile(file)">
                                                                        {{ file.name }}
                                                                    </a>
                                                                </v-chip>
                                                                <div class="font-size__small">
                                                                    {{ $moment(file.createdAt).dateTimeFormat() }}
                                                                </div>
                                                            </drag>
                                                        </div>
                                                    </div>
                                                </div>
                                            </drop>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-alert v-if="form.errors.any()" dense outlined type="error" :icon="false">
                        Исправьте ошибки
                    </v-alert>
                    <v-alert v-if="form.errors.has('error')" dense outlined type="error" :icon="false">
                        {{ form.errors.get('error') }}
                    </v-alert>
                    <div v-show="!form.busy">
                        <v-btn
                            v-if="$helper.permission.canSaveContract(user)"
                            v-show="form.hasChanges"
                            large
                            color="green"
                            @click="(contract && contract.id) ? update(false) : save(false)">
                            Сохранить
                        </v-btn>
                        <v-btn
                            v-if="$helper.permission.canSaveContract(user)"
                            v-show="form.hasChanges"
                            large
                            color="green"
                            @click="(contract && contract.id) ? update(true) : save(false)"
                            class="ml-4">
                            Сохранить и закрыть
                        </v-btn>
                    </div>
                    <v-progress-circular v-show="form.busy" indeterminate color="primary"></v-progress-circular>
                </form>
            </v-card-text>
        </v-card>

        <v-expansion-panels class="mt-5 task-expansion" v-if="(contract && contract.id)">
            <v-expansion-panel v-if="taskForms.legalExpert">
                <v-expansion-panel-header>
                    <v-card-title class="px-1 py-2">Задание в отдел исполнения</v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-col>
                            <MultiField
                                field-title="Общее описание ситуации клиента"
                                :form-field="taskForms.legalExpert?.documents"
                                :field-initial-state="initialState.taskLegalExpert?.documents"
                                class="mb-5">
                            </MultiField>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <MultiField
                                field-title="Цель клиента"
                                :form-field="taskForms.legalExpert?.clientPosition"
                                :field-initial-state="initialState.taskLegalExpert?.clientPosition"
                                class="mb-5">
                            </MultiField>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <MultiField
                                field-title="Оговоренная стратегия (на что ориентирован клиент) *"
                                :form-field="taskForms.legalExpert?.clientOrientation"
                                :field-initial-state="initialState.taskLegalExpert?.clientOrientation"
                                class="mb-5">
                            </MultiField>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <MultiField
                                field-title="Действия эксперта"
                                :form-field="taskForms.legalExpert?.expertActions"
                                :field-initial-state="initialState.taskLegalExpert?.expertActions"
                                class="mb-5">
                            </MultiField>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <DateField
                                :form="form"
                                v-model="taskForms.legalExpert.readyDate"
                                label="Дата готовности документов"
                                fieldPath="taskLegalExpert.readyDate"
                            ></DateField>
                            <v-alert v-if="taskForms.legalExpert.errors.has('readyDate')" dense outlined type="error" :icon="false">
                                {{ taskForms.legalExpert.errors.get('readyDate') }}
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <DateField
                                :form="form"
                                v-model="taskForms.legalExpert.sentDate"
                                label="Дата подачи (процессуальные сроки)"
                                fieldPath="taskLegalExpert.sentDate"
                            ></DateField>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <MultiField
                                field-title="Действия представителя"
                                :form-field="taskForms.legalExpert?.representativeActions"
                                :field-initial-state="initialState.taskLegalExpert?.representativeActions"
                                class="mb-5">
                            </MultiField>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <MultiField
                                field-title="Заполняет руководитель отдела исполнения"
                                :form-field="taskForms.legalExpert?.executionDepartmentHead"
                                :field-initial-state="initialState.taskLegalExpert?.executionDepartmentHead"
                                class="mb-5">
                            </MultiField>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <DateField
                                :form="form"
                                v-model="taskForms.legalExpert.executionTerms"
                                label="Сроки исполнения"
                                fieldPath="taskLegalExpert.executionTerms"
                            ></DateField>
                        </v-col>
                    </v-row>
                    <h3>Подпись юриста</h3>
                    <div class="mt-2 mb-5">
                        <v-row v-if="user.role == 'LAWYER' || user.role == 'LAWYER_OGPIA' || user.role == 'LAWYER_EPO'">
                            <v-col>
                                <v-btn small outlined color="green" @click="signTaskLegalExpert()">
                                    <div>Подписать</div>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <Signatures :signatures="form.signaturesTaskLegalExpertLawyer"></Signatures>
                            </v-col>
                        </v-row>
                    </div>
                    <h3>Подпись руководителя</h3>
                    <div class="mt-2 mb-5">
                        <v-row
                            v-if="user.role == 'LAWYER_MANAGER' || user.role == 'MANAGER_EPO' || user.role == 'ADMIN' || user.role == 'MANAGER_OGPIA' || user.role == 'MANAGER_OKK'">
                            <v-col>
                                <v-btn small outlined color="green" @click="signTaskLegalExpert()">
                                    <div>Подписать</div>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <Signatures :signatures="form.signaturesTaskLegalExpertManager"></Signatures>
                            </v-col>
                        </v-row>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="taskForms.civilianRepr">
                <v-expansion-panel-header>
                    <v-card-title class="px-1 py-2">Задание в отдел гражданских представителей и адвокатов
                    </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-checkbox
                        v-model="taskForms.civilianRepr.docContract"
                        label="Договор (копия всех листов договора / чеки отдельно) *">
                        >
                    </v-checkbox>
                    <v-checkbox
                        v-model="taskForms.civilianRepr.docApplication"
                        label="Приложение №1 к Договору *">
                        >
                    </v-checkbox>
                    <v-checkbox
                        v-model="taskForms.civilianRepr.docProxy"
                        label="Доверенность(нотариально заверенная копия для суда) *">
                        >
                    </v-checkbox>
                    <v-checkbox
                        v-model="taskForms.civilianRepr.docTransport"
                        label="Транспортные">
                        >
                    </v-checkbox>
                    <v-row>
                        <v-col>
                            <v-textarea v-model="taskForms.civilianRepr.docCopies"
                                        label="Копии иных документов"
                                        rows="3"
                                        auto-grow>
                            </v-textarea>
                            <v-alert v-if="taskForms.civilianRepr.errors.has('docCopies')" dense outlined type="error"
                                     :icon="false">
                                {{ taskForms.civilianRepr.errors.get('docCopies') }}
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea
                                v-model="taskForms.civilianRepr.clientSituation"
                                label="Ситуация клиента *"
                                rows="2"
                                auto-grow>
                            </v-textarea>
                            <v-alert v-if="taskForms.civilianRepr.errors.has('clientSituation')" dense outlined type="error" :icon="false">
                                {{ taskForms.civilianRepr.errors.get('clientSituation') }}
                            </v-alert>
                        </v-col>s
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea
                                v-model="taskForms.civilianRepr.clientAim"
                                label="Цель клиента по данному Договору (не предмет Договора!) *"
                                rows="2"
                                auto-grow>
                            </v-textarea>
                            <v-alert v-if="taskForms.civilianRepr.errors.has('clientAim')" dense outlined type="error" :icon="false">
                                {{ taskForms.civilianRepr.errors.get('clientAim') }}
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea
                                v-model="taskForms.civilianRepr.clientOrientation"
                                label="На что ориентировал Клиента юрист по договору"
                                hint="при ориентировании клиента на сроки: сроки созвона с клиентом, срок подготовки документов, дополнительные документы сверх предмета договора, доп действия по Договору"
                                rows="2"
                                auto-grow>
                            </v-textarea>
                            <v-alert
                                v-if="taskForms.civilianRepr.errors.has('clientOrientation')"
                                dense
                                outlined
                                type="error"
                                :icon="false">
                                {{ taskForms.civilianRepr.errors.get('clientOrientation') }}
                            </v-alert>
                        </v-col>
                    </v-row>
                    <div class="mb-5 pb-3">
                        *поля обязательные к заполнению. В случае не предоставления информации, представитель не
                        приступает к исполнению Договора.
                        <br>
                        ВНИМАНИЕ!!! СРОК ПОДГОТОВКИ ДОКУМЕНТОВ 3-4 ДНЯ, ПРАВОВОЙ АНАЛИЗ 3-4 ДНЯ, ДОСУДЕБКА В ТЕЧЕНИИ 5
                        ТИ РАБОЧИХ ДНЕЙ.
                        <br>
                        *СРОЧНЫЙ ВЫЕЗД/СУД, ГДЕ НАЗНАЧЕНА ДАТА С/З, ТОЛЬКО С СОГЛАСОВАНИЯ С РУКОВОДИТЕЛЕМ/ ЗАМЕСТИТЕЛЕМ
                        ОТДЕЛА ПРЕДСТАВИТЕЛЕЙ.
                        <br>
                        *Дела, в отдел представителей, передаются только с резолюцией руководителя юридического отдела
                        <br>
                        *Без полной оплаты договоры принимаются только с резолюцией директора и руководителя юр.отдела
                    </div>
                    <h3>Подпись юриста</h3>
                    <div class="mb-5">
                        <v-row
                            class="d-none"
                            v-if="user.role == 'LAWYER' || user.role == 'LAWYER_OGPIA' || user.role == 'LAWYER_EPO'">
                            <v-col>
                                <v-btn small outlined color="green" @click="signTaskCivilianRepr()">
                                    <div>Подписать</div>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <Signatures :signatures="form.signaturesTaskCivilianReprLawyer"></Signatures>
                            </v-col>
                        </v-row>
                    </div>
                    <h3>Подпись руководителя</h3>
                    <div class="mb-5">
                        <v-row
                            class="d-none"
                            v-if="user.role == 'LAWYER_MANAGER' || user.role == 'ADMIN' || user.role == 'MANAGER_EPO' || user.role == 'MANAGER_OKK'">
                            <v-col>
                                <v-btn small outlined color="green" @click="signTaskCivilianRepr()">
                                    <div>Подписать</div>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <Signatures :signatures="form.signaturesTaskCivilianReprManager"></Signatures>
                            </v-col>
                        </v-row>
                    </div>
                    <h3>Заполняет руководитель отдела представителей</h3>
                    <v-row>
                        <v-col>
                            <v-textarea
                                v-model="taskForms.civilianRepr.workNeeded"
                                label="Необходимая работа, срок исполнения"
                                rows="2"
                                auto-grow>
                            </v-textarea>
                            <v-alert v-if="taskForms.civilianRepr.errors.has('workNeeded')" dense outlined type="error"
                                     :icon="false">
                                {{ taskForms.civilianRepr.errors.get('workNeeded') }}
                            </v-alert>
                        </v-col>
                    </v-row>
                    <h3>Подпись руководителя</h3>
                    <div class="mb-5">
                        <v-row v-if="user.role == 'MANAGER_OGPIA'">
                            <v-col>
                                <v-btn small outlined color="green" @click="signTaskCivilianRepr()">
                                    <div>Подписать</div>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <Signatures
                                    :signatures="form.signaturesTaskCivilianReprRepresentativeManager"></Signatures>
                            </v-col>
                        </v-row>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="taskForms.addLegalExpert">
                <v-expansion-panel-header>
                    <v-card-title class="px-1 py-2">
                        Дополнительное задание в экспертно-правовой отдел
                    </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-col>
                            <v-textarea
                                v-model="taskForms.addLegalExpert.documents"
                                label="Документы"
                                rows="3"
                                auto-grow>
                            </v-textarea>
                            <v-alert
                                v-if="taskForms.addLegalExpert.errors.has('documents')" dense outlined type="error"
                                :icon="false">
                                {{ taskForms.addLegalExpert.errors.get('documents') }}
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea
                                v-model="taskForms.addLegalExpert.clientPosition"
                                label="Правовая позиция клиента"
                                rows="2"
                                auto-grow>
                            </v-textarea>
                            <v-alert
                                v-if="taskForms.addLegalExpert.errors.has('clientPosition')" dense outlined
                                type="error" :icon="false">
                                {{ taskForms.addLegalExpert.errors.get('clientPosition') }}
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea
                                v-model="taskForms.addLegalExpert.clientOrientation"
                                label="На что ориентировал Клиента юрист по Договору *"
                                rows="2"
                                auto-grow>
                            </v-textarea>
                            <v-alert
                                v-if="taskForms.addLegalExpert.errors.has('clientOrientation')" dense outlined
                                type="error" :icon="false">
                                {{ taskForms.addLegalExpert.errors.get('clientOrientation') }}
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <DateField
                                :form="form"
                                v-model="taskForms.addLegalExpert.readyDate"
                                label="Дата готовности пакета"
                                fieldPath="addTaskLegalExpert.readyDate"
                            ></DateField>
                        </v-col>
                    </v-row>
                    <h3>Подпись юриста</h3>
                    <div class="mb-5">
                        <v-row v-if="user.role == 'LAWYER' || user.role == 'LAWYER_OGPIA' || user.role == 'LAWYER_EPO'">
                            <v-col>
                                <v-btn small outlined color="green" @click="signAddTaskLegalExpert()">
                                    <div>Подписать</div>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <Signatures :signatures="form.signaturesAddTaskLegalExpertLawyer"></Signatures>
                            </v-col>
                        </v-row>
                    </div>
                    <h3>Подпись руководителя</h3>
                    <div class="mb-5">
                        <v-row
                            v-if="user.role == 'LAWYER_MANAGER' || user.role == 'MANAGER_EPO' || user.role == 'ADMIN' || user.role == 'MANAGER_OGPIA' || user.role == 'MANAGER_OKK'">
                            <v-col>
                                <v-btn small outlined color="green" @click="signAddTaskLegalExpert()">
                                    <div>Подписать</div>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <Signatures :signatures="form.signaturesAddTaskLegalExpertManager"></Signatures>
                            </v-col>
                        </v-row>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <ContractAdditionalAgreement
            v-if="isFieldVisible('ContractAdditionalAgreement') && contract && contract.id"
            :id="contract.id">
        </ContractAdditionalAgreement>

        <Events
            :id="contract.id"
            :types="page.eventTypes"
            :executors="page.executors"
            @saved="onEventAdd"
            v-if="isFieldVisible('Events') && contract && contract.id">
        </Events>

        <ContactChannels
            :id="contract.id"
            :key="contactChannelsGridKey"
            v-if="isFieldVisible('ContactChannels') && contract && contract.id">
        </ContactChannels>

        <ContractCalls
            v-if="contract && $helper.permission.canSeeContractCalls(user) && $_.get(contract, 'client.userPhone')"
            :title="`Исх. вызовы для номера: ${contract.client.userPhone}`"
            :filter="{callingNumber: contract.client.userPhone.replace(/\D+/g, '')}">
        </ContractCalls>

        <ContractCalls
            v-if="contract && $helper.permission.canSeeContractCalls(user) && $_.get(contract, 'client.userPhone')"
            :title="`Вх. вызовы для номера: ${contract.client.userPhone}`"
            :filter="{calledNumber: contract.client.userPhone.replace(/\D+/g, '')}">
        </ContractCalls>

        <ContractCalls
            v-if="contract && $helper.permission.canSeeContractCalls(user) && contract.clientPhone"
            :title="`Исх. вызовы для номера: ${contract.clientPhone}`"
            :filter="{callingNumber: contract.clientPhone.replace(/\D+/g, '')}">
        </ContractCalls>

        <ContractCalls
            v-if="contract && $helper.permission.canSeeContractCalls(user) && contract.clientPhone"
            :title="`Вх. вызовы для номера: ${contract.clientPhone}`"
            :filter="{calledNumber: contract.clientPhone?.replace(/\D+/g, '')}">
        </ContractCalls>

        <ContractPaymentsGrid
            v-if="isFieldVisible('ContractPayments') && contract && contract.id"
            title="Оплаты"
            :key="contractPaymentsKey"
            :contract="form"
            :filter="{ 'contract.id': page.contract.id }"
            :columns="$helper.contract__ContractPaymentColumns.getForRole(user.role)">
        </ContractPaymentsGrid>

        <ContractAdditionalPayments
            v-if="isFieldVisible('ContractAdditionalPayments') && contract && contract.id"
            :contract="page.contract">
        </ContractAdditionalPayments>

        <ContractActs
            v-if="isFieldVisible('ContractActs') && contract && contract.id"
            :id="contract.id"
            :contract="contract"
            :lawyers="lawyers"
            :lawyersOGPA="lawyersOGPA"
            :lawyersEPO="lawyersEPO">
        </ContractActs>

        <ContractComments
            :id="contract.id"
            :types="page.commentTypes"
            :users="page.users"
            v-if="isFieldVisible('ContractComments') && contract && contract.id">
        </ContractComments>

        <NotificationsGrid
            :id="contract.id"
            :users="page.users"
            type="Contract"
            v-if="isFieldVisible('NotificationsGrid') && contract && contract.id">
        </NotificationsGrid>

        <ContractVisits
            class="mb-5"
            v-if="contract && contract.id"
            :id="contract.id">
        </ContractVisits>

        <ContractRevisions
            class="mb-5"
            v-if="contract && contract.id"
            :id="contract.id"
            :page="page">
        </ContractRevisions>

        <v-bottom-sheet v-model="dialog.playRecord.modal" persistent>
            <v-sheet class="text-center" height="200px">
                <v-btn class="mt-6" text color="error" @click="closePlayAudio()">закрыть</v-btn>
                <div class="py-3">
                    <PlayRecord v-if="audioUrl" :audioUrl="audioUrl"></PlayRecord>
                </div>
            </v-sheet>
        </v-bottom-sheet>

        <v-dialog v-model="dialog.commentForBuhgalter" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">Комментарий для бухгалтера</span>
                </v-card-title>
                <v-card-text>
                    <v-textarea auto-grow v-model="form.commentForBuhgalter" label="Комментарий"></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog.commentForBuhgalter = false">Готово</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <Loading :value="fullPageLoading"></Loading>
    </div>
</template>

<script>
import {Form} from '../_helpers/_form';
import ContractService from "../_services/ContractService";
import {mapState} from 'vuex';
import ContractAdditionalPayments from "./payments/ContractAdditionalPayments";
import ContractActs from "./acts/ContractActs";
import ContractAdditionalAgreement from "./additional-agreements/ContractAdditionalAgreement";
import ContractComments from "./comments/ContractComments";
import {formFields} from "./settings/ContractSettings";
import NotificationsGrid from "../notifications/NotificationsGrid";
import PlayRecord from "./PlayRecord";
import FileService from "../_services/FileService";
import ContractCalls from "./calls/ContractCalls";
import ContractPaymentsGrid from "./payments/ContractPaymentsGrid";
import Signatures from "./signatures/Signatures";
import Loading from "../_chunks/Loading";
import DateField from "../_chunks/DateField";
import ContractRevisions from "./revisions/ContractRevisions";
import ContractAction from "./action/ContractAction";
import ContactChannels from "@/contracts/contact-channels/ContactChannels.vue";
import Events from "@/contracts/events/Events.vue";
import { Drag, Drop } from 'vue-drag-drop';
import MultiField from "@/_components/MultiField.vue";
import {deepCopy} from "@/_helpers/_form/util";
import ContractVisits from "@/contracts/visits/ContractVisits.vue";

export default {
    name: "ContractForm",
    components: {
        ContractVisits,
        MultiField,
        Events,
        ContactChannels,
        ContractAction,
        ContractRevisions,
        DateField,
        Loading,
        Signatures,
        ContractPaymentsGrid,
        ContractCalls,
        NotificationsGrid,
        ContractComments,
        ContractAdditionalAgreement,
        ContractActs,
        ContractAdditionalPayments,
        PlayRecord,
        Drag,
        Drop
    },
    props: {
        page: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            datetime: new Date(),
            form: this.page.contract
                ? new Form(this.page.contract)
                : new Form({ phase: 'OPREDELIT_ETAP' }),
            fileUploadForm: new Form({
                directory: {
                    id: 1
                }
            }),
            lawyers: this.page.lawyers,
            lawyersOGPA: this.page.lawyersOGPA,
            lawyersEPO: this.page.lawyersEPO,
            users: this.page.users,
            types: this.page.types,
            subjects: this.page.subjects,
            areas: this.page.areas,
            contract: this.page.contract,
            offices: this.page.offices,
            loading: false,
            formFields: formFields,
            dateDialog: false,
            courtDateTimeDialog: false,
            states: this.page.states, //only manual driven states are stored here
            fileDirectories: this.page.fileDirectories,
            files: [],
            taskForms: {
                civilianRepr: this.page.contract ? new Form({...this.page.contract.taskCivilianRepresentatives}) : null,
                legalExpert: this.page.contract ? new Form({...this.page.contract.taskLegalExpert}) : null,
                addLegalExpert: this.page.contract ? new Form({...this.page.contract.taskAddLegalExpert}) : null,
            },
            initialState: this.page.contract
                ? deepCopy(this.page.contract)
                : null,
            fileRules: [
                // eslint-disable-next-line no-unused-vars
                values => {
                    return true;
                    // let hasError = false;
                    // values.forEach(value => {
                    //     if (value.size > 52428800) hasError = true;
                    // });
                    // return !hasError || 'Файлы должны быть не более 50 MB!';
                }
            ],
            fileLoading: (this.page.contract && this.page.contract.files) ? this.$helper.convertArrayToObject(this.page.contract.files, 'id', false) : {},
            fileUploading: false,
            dialog: {
                playRecord: {
                    modal: false
                },
                commentForBuhgalter: false
            },
            audioUrl: null,
            fullPageLoading: false,
            contactChannelsGridKey: 1,
            contractPaymentsKey: 1
        }
    },
    watch: {
        files: {
            handler() {
                this.form.hasChanges = true;
            },
        },
        form: {
            handler() {
                this.form.hasChanges = true;
            },
            deep: true
        }
    },
    methods: {
        save() {
            this.enrichFormAsMultipart(this.form);
            ContractService.create(this.form).then(() => {
                this.$router.push('/contracts');
            });
        },
        update(needToExit) {
            this.enrichFormAsMultipart(this.form);
            ContractService.update(this.contract.id, this.form).then(() => {
                this.contractPaymentsKey++;
                if (needToExit) {
                    this.$emit('closeAndUpdate');
                } else {
                    this.$emit('updated');
                }
            });
        },
        enrichFormAsMultipart(form, type = 'contract') {
            let formData = new FormData();
            form.multipartData = null;
            this.files.forEach((file) => {
                formData.append('files', file, file.name);
            });
            formData.append(type, new Blob([JSON.stringify(form.data())], {type: "application/json"}));
            form.multipartData = formData;
        },
        uploadFiles() {
            this.enrichFormAsMultipart(this.fileUploadForm, "form");
            this.fileUploading = true;
            ContractService.uploadFiles(this.contract.id, this.fileUploadForm).then(() => {
                this.files = [];
                this.updateFiles();
                this.fileUploading = false;
            });
        },
        updateFiles() {
            ContractService.getForm(this.contract.id).then(data => {
                this.form.files = data.contract.files;
            }, error => {
                this.flashError(this.$helper.showError(error));
            });
        },
        isManualDrivenState(requestState) {
            let state = this.states.find(v => v.value === requestState);
            if (!state) {
                return true;
            } else {
                return state.manualDriven === 'true' || state.value === 'NEW';
            }
        },
        onChangeState(state) {
            console.log(state);
        },
        downloadFile(file) {
            this.fileLoading[file.id] = true;
            FileService.serveFile(file.id, this).finally(() => {
                this.fileLoading[file.id] = false;
            });
        },
        removeFile(file) {
            if (this.form.files) {
                this.$helper.removeOne(this.form.files, row => row.id === file.id);
            }
        },
        closePlayAudio() {
            this.dialog.playRecord.modal = !this.dialog.playRecord.modal;
        },
        playAudio(audioUrl) {
            this.audioUrl = audioUrl;
            this.dialog.playRecord.modal = true;
        },
        isFieldVisible(field) {
            let userVisibleFields = this.formFields.visibleFieldsForRole[this.user.role];
            if (userVisibleFields && !userVisibleFields.includes(field)) {
                return false;
            }
            return true;
        },
        isFieldVisibleOnlyFor(field) {
            let userVisibleFieldsOnlyFor = this.formFields.visibleFieldsOnlyForRole[field];
            if (userVisibleFieldsOnlyFor && userVisibleFieldsOnlyFor.includes(this.user.role)) {
                return true;
            }
            return false;
        },
        handleChangeSentForBuhgalter() {
            if (this.form.isSentForBuhgalter) {
                this.dialog.commentForBuhgalter = true
            }
        },
        signTaskCivilianRepr() {
            this.fullPageLoading = true;
            ContractService.signTaskCivilianRepr(this.contract.id, this.taskForms.civilianRepr).then(contract => {
                this.form.signaturesTaskCivilianReprLawyer = contract.data.signaturesTaskCivilianReprLawyer;
                this.form.signaturesTaskCivilianReprManager = contract.data.signaturesTaskCivilianReprManager;
                this.form.signaturesTaskCivilianReprRepresentativeManager = contract.data.signaturesTaskCivilianReprRepresentativeManager;
            }).finally(() => {
                this.fullPageLoading = false;
            });
        },
        signTaskLegalExpert() {
            this.fullPageLoading = true;
            ContractService.signTaskLegalExpert(this.contract.id, this.taskForms.legalExpert).then(contract => {
                this.form.signaturesTaskLegalExpertLawyer = contract.data.signaturesTaskLegalExpertLawyer;
                this.form.signaturesTaskLegalExpertManager = contract.data.signaturesTaskLegalExpertManager;
            }).finally(() => {
                this.fullPageLoading = false;
            });
        },
        signAddTaskLegalExpert() {
            this.fullPageLoading = true;
            ContractService.signAddTaskLegalExpert(this.contract.id, this.taskForms.addLegalExpert).then(contract => {
                this.form.signaturesAddTaskLegalExpertLawyer = contract.data.signaturesAddTaskLegalExpertLawyer;
                this.form.signaturesAddTaskLegalExpertManager = contract.data.signaturesAddTaskLegalExpertManager;
            }).finally(() => {
                this.fullPageLoading = false;
            });
        },
        addNewFlow() {
            this.form.contractFlows.push({});
        },
        removeFlow(index) {
            this.form.contractFlows.splice(index, 1);
        },
        addToFavorites(contract) {
            ContractService.addToFavorites(contract.id).then(() => {
                contract.inFavorites = true;
                this.$emit('updated');
            });
        },
        removeFromFavorites(contract) {
            ContractService.removeFromFavorites(contract.id).then(() => {
                contract.inFavorites = false;
                this.$emit('updated');
            });
        },
        deleteSentForDocumentsActions(actionId) {
            ContractService.deleteSentForDocumentsActions(this.contract.id, actionId).then(() => {
                this.form.sentForDocumentsActions = this.form.sentForDocumentsActions.filter(action => action.id != actionId);
                this.$emit('updated');
            });
        },
        deleteDocumentsAreReadyActions(actionId) {
            ContractService.deleteDocumentsAreReadyActions(this.contract.id, actionId).then(() => {
                this.form.documentsAreReadyActions = this.form.documentsAreReadyActions.filter(action => action.id != actionId);
                this.$emit('updated');
            });
        },
        deleteSentDosudebkaEPOActions(actionId) {
            ContractService.deleteSentDosudebkaEPOActions(this.contract.id, actionId).then(() => {
                this.form.sentDosudebkaEPOActions = this.form.sentDosudebkaEPOActions.filter(action => action.id != actionId);
                this.$emit('updated');
            });
        },
        deleteTransferredToCourtActions(actionId) {
            ContractService.deleteTransferredToCourtActions(this.contract.id, actionId).then(() => {
                this.form.transferredToCourtActions = this.form.transferredToCourtActions.filter(action => action.id != actionId);
                this.$emit('updated');
            });
        },
        deleteSentToCourtActions(actionId) {
            ContractService.deleteSentToCourtActions(this.contract.id, actionId).then(() => {
                this.form.sentToCourtActions = this.form.sentToCourtActions.filter(action => action.id != actionId);
                this.$emit('updated');
            });
        },
        deleteTransferredToOGPAActions(actionId) {
            ContractService.deleteTransferredToOGPAActions(this.contract.id, actionId).then(() => {
                this.form.transferredToOGPAActions = this.form.transferredToOGPAActions.filter(action => action.id != actionId);
                this.$emit('updated');
            });
        },
        deletePackageFromLenyrActions(actionId) {
            ContractService.deletePackageFromLenyrActions(this.contract.id, actionId).then(() => {
                this.form.packageFromLenyrActions = this.form.packageFromLenyrActions.filter(action => action.id != actionId);
                this.$emit('updated');
            });
        },
        deleteSentToOkkActions(actionId) {
            ContractService.deleteSentToOkkActions(this.contract.id, actionId).then(() => {
                this.form.sentToOkkActions = this.form.sentToOkkActions.filter(action => action.id != actionId);
                this.$emit('updated');
            });
        },
        deleteAdditionalPackageIsReadyActions(actionId) {
            ContractService.deleteAdditionalPackageIsReadyActions(this.contract.id, actionId).then(() => {
                this.form.additionalPackageIsReadyActions = this.form.additionalPackageIsReadyActions.filter(action => action.id != actionId);
                this.$emit('updated');
            });
        },
        onEventAdd() {
            console.log('test');
            this.contactChannelsGridKey++;
        },
        handleFileDirectoryDrop(dirId, data) {
            console.log("Dir:" + dirId);
            console.log("File:" + data.fileId);

            FileService
                .changeFileDirectory(dirId, data.fileId)
                .then(() => {
                    const file = this.form.files.find(file => file.id === data.fileId);
                    file.directory = {
                        id: dirId
                    }
                })
                .catch(error => {
                    this.flashError(this.$helper.showError(error));
                });
        },
        handleDirectoryCLick(dir) {
            dir.opened = !dir.opened;
            this.fileDirectories = [...this.fileDirectories];
        },
        getDirectorySize(dir) {
            const fileSize = this.form.files?.filter(file => file.directory?.id === dir.id).length;
            return fileSize ? `<span class="ml-1">${fileSize}</span>` : "";
        }
    },
    computed: {
        ...mapState(['user']),
        phases() {
            return this.page.phases.filter(phase => {
                return !(!this.$helper.permission.canSetContractPhase(this.user, phase) && this.form.phase !== phase.value);
            });
        },
        payedAmount() {
            return this.form.amount - this.form.payedAmount;
        },
        filesWithoutDirectories() {
            return this.form.files.filter(file => !file.directory?.id);
        }
        // fileLoading() {
        //     if (this.form && this.form.files) {
        //         let loadings = {}
        //         this.form.files.forEach(el => {
        //             loadings[el.id] = true
        //         })
        //         return loadings
        //     }
        //     return {}
        // }
    },
    created: function () {
        this.$parent.$on('forceUpdateForm', () => {
            if (!this.page?.contract) {
                return;
            }
            this.loading = true;
            ContractService.getForm(this.page?.contract?.id).then(data => {
                this.form = new Form(data.contract);
                this.loading = false;
            }, error => {
                this.loading = false;
                this.flashError(this.$helper.showError(error));
            });
        });
    }
}
</script>

<style lang="scss">
.task-expansion {
    .v-card__title {
        color: #FDD835
    }
}

.flow_comment {
    margin: -25px 0 0;
    border-bottom: 1px solid #7f7f7f;
}

.input__label_wrapper {
    position: relative;
    width: 100%;
}

.input__label {
    transform: scale(0.75);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    font-size: 16px;
    left: 0px;
    right: auto;
    position: absolute;
    color: rgba(255, 255, 255, 0.5);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 135%;
}
</style>
