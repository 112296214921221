var render = function () {
  var _vm$page, _vm$page$contract, _vm$taskForms$legalEx, _vm$initialState$task, _vm$taskForms$legalEx2, _vm$initialState$task2, _vm$taskForms$legalEx3, _vm$initialState$task3, _vm$taskForms$legalEx4, _vm$initialState$task4, _vm$taskForms$legalEx5, _vm$initialState$task5, _vm$taskForms$legalEx6, _vm$initialState$task6, _vm$contract$clientPh;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, table-row@3, button"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('v-card', [_c('v-card-text', [_c('form', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "9"
    }
  }, [_c('v-row', [_vm.form.id ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm.form.inFavorites ? _c('v-icon', {
    staticClass: "ml-1",
    attrs: {
      "color": "yellow"
    },
    on: {
      "click": function click($event) {
        return _vm.removeFromFavorites(_vm.form);
      }
    }
  }, [_vm._v(" mdi-star ")]) : _vm._e(), !_vm.form.inFavorites ? _c('v-icon', {
    staticClass: "ml-1",
    attrs: {
      "color": "grey"
    },
    on: {
      "click": function click($event) {
        return _vm.addToFavorites(_vm.form);
      }
    }
  }, [_vm._v(" mdi-star-outline ")]) : _vm._e()], 1) : _vm._e(), _vm.form.id ? _c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.form.id,
      "disabled": "disabled",
      "label": "ID"
    }
  })], 1) : _vm._e(), _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.states,
      "disabled": !_vm.isManualDrivenState(_vm.form.state) && !_vm.$helper.permission.canChangeContractNotManualState(_vm.user, _vm.form.state) || !_vm.formFields.state.isEnabled(_vm.user),
      "label": "Статус"
    },
    on: {
      "change": _vm.onChangeState
    },
    model: {
      value: _vm.form.state,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "state", $$v);
      },
      expression: "form.state"
    }
  }), _vm.form.errors.has('state') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('state')) + " ")]) : _vm._e()], 1), _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.phases,
      "label": "Этап исполнения"
    },
    model: {
      value: _vm.form.phase,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "phase", $$v);
      },
      expression: "form.phase"
    }
  }), _vm.form.errors.has('phase') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('phase')) + " ")]) : _vm._e()], 1), _vm.form.id ? _c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.form.createdAt,
      "disabled": "disabled",
      "label": "Дата создания"
    }
  })], 1) : _vm._e(), _c('v-col', [_c('v-dialog', {
    ref: "dateDialog",
    attrs: {
      "return-value": _vm.form.date,
      "persistent": "",
      "width": "290px"
    },
    on: {
      "update:returnValue": function updateReturnValue($event) {
        return _vm.$set(_vm.form, "date", $event);
      },
      "update:return-value": function updateReturnValue($event) {
        return _vm.$set(_vm.form, "date", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "disabled": !_vm.formFields.date.isEnabled(_vm.user),
            "label": "Дата регистрации",
            "prepend-icon": "mdi-calendar-month-outline",
            "readonly": ""
          },
          model: {
            value: _vm.form.date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "date", $$v);
            },
            expression: "form.date"
          }
        }, on))];
      }
    }], null, false, 1643534032),
    model: {
      value: _vm.dateDialog,
      callback: function callback($$v) {
        _vm.dateDialog = $$v;
      },
      expression: "dateDialog"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "type": "date",
      "scrollable": "",
      "first-day-of-week": "1"
    },
    model: {
      value: _vm.form.date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "date", $$v);
      },
      expression: "form.date"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.dateDialog = false;
      }
    }
  }, [_vm._v("Отмена")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.dateDialog.save(_vm.form.date);
      }
    }
  }, [_vm._v(" OK ")])], 1)], 1), _vm.form.errors.has('date') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('date')) + " ")]) : _vm._e()], 1)], 1), _vm.contract !== null ? _c('v-row', [_vm.isFieldVisible('sentForDocumentsPreparationAt') ? _c('v-col', [_c('ContractAction', {
    attrs: {
      "actions": _vm.form.sentForDocumentsActions,
      "label": "Дата отправки на подготовку документов"
    },
    on: {
      "deleteAction": function deleteAction($event) {
        return _vm.deleteSentForDocumentsActions($event);
      }
    }
  })], 1) : _vm._e(), _vm.isFieldVisible('documentsAreReadyAt') ? _c('v-col', [_c('ContractAction', {
    attrs: {
      "actions": _vm.form.documentsAreReadyActions,
      "label": "Документы готовы"
    },
    on: {
      "deleteAction": function deleteAction($event) {
        return _vm.deleteDocumentsAreReadyActions($event);
      }
    }
  })], 1) : _vm._e(), _vm.isFieldVisible('sentDosudebkaEPO') ? _c('v-col', [_c('ContractAction', {
    attrs: {
      "actions": _vm.form.sentDosudebkaEPOActions,
      "label": "Досудебка ЭПО"
    },
    on: {
      "deleteAction": function deleteAction($event) {
        return _vm.deleteSentDosudebkaEPOActions($event);
      }
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.contract !== null ? _c('v-row', [_vm.isFieldVisible('transferredToCourt') ? _c('v-col', [_c('ContractAction', {
    attrs: {
      "actions": _vm.form.transferredToCourtActions,
      "label": "Передано на подачу в суд"
    },
    on: {
      "deleteAction": function deleteAction($event) {
        return _vm.deleteTransferredToCourtActions($event);
      }
    }
  })], 1) : _vm._e(), _vm.isFieldVisible('sentToCourt') ? _c('v-col', [_c('ContractAction', {
    attrs: {
      "actions": _vm.form.sentToCourtActions,
      "label": "Подано в суд"
    },
    on: {
      "deleteAction": function deleteAction($event) {
        return _vm.deleteSentToCourtActions($event);
      }
    }
  })], 1) : _vm._e(), _vm.isFieldVisible('transferredToOGPAAt') ? _c('v-col', [_c('ContractAction', {
    attrs: {
      "actions": _vm.form.transferredToOGPAActions,
      "label": "Передано в ОГПиА"
    },
    on: {
      "deleteAction": function deleteAction($event) {
        return _vm.deleteTransferredToOGPAActions($event);
      }
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.contract !== null ? _c('v-row', [_vm.isFieldVisible('additionalPackageIsReady') ? _c('v-col', [_c('ContractAction', {
    attrs: {
      "actions": _vm.form.additionalPackageIsReadyActions,
      "label": "Доп. пакет готов к выдаче"
    },
    on: {
      "deleteAction": function deleteAction($event) {
        return _vm.deleteAdditionalPackageIsReadyActions($event);
      }
    }
  })], 1) : _vm._e(), _vm.isFieldVisible('packageToLenyr') ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('ContractAction', {
    attrs: {
      "actions": _vm.form.packageFromLenyrActions,
      "label": "Пакет от ЛенЮр"
    },
    on: {
      "deleteAction": function deleteAction($event) {
        return _vm.deletePackageFromLenyrActions($event);
      }
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Номер",
      "disabled": !_vm.formFields.number.isEnabled(_vm.user)
    },
    model: {
      value: _vm.form.number,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "number", $$v);
      },
      expression: "form.number"
    }
  }), _vm.form.errors.has('number') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('number')) + " ")]) : _vm._e()], 1), _vm.isFieldVisible('type') ? _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.types,
      "label": "Вид",
      "clearable": true,
      "disabled": !_vm.formFields.type.isEnabled(_vm.user)
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _vm.form.errors.has('type') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('type')) + " ")]) : _vm._e()], 1) : _vm._e(), _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.subjects,
      "label": "Предмет",
      "clearable": true,
      "disabled": !_vm.formFields.subject.isEnabled(_vm.user)
    },
    model: {
      value: _vm.form.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  }), _vm.form.errors.has('subject') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('subject')) + " ")]) : _vm._e()], 1), _c('v-col', [_c('v-datetime-picker', {
    attrs: {
      "type": "date",
      "scrollable": "",
      "label": "Дата суда",
      "disabled": !_vm.formFields.courtDateTime.isEnabled(_vm.user),
      "clearText": "Удалить",
      "okText": "Сохранить",
      "datePickerProps": {
        'first-day-of-week': '1'
      },
      "timePickerProps": {
        'format': '24h'
      }
    },
    model: {
      value: _vm.form.courtDateTime,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "courtDateTime", $$v);
      },
      expression: "form.courtDateTime"
    }
  }), _vm.form.errors.has('courtDateTime') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('courtDateTime')) + " ")]) : _vm._e()], 1), _vm.isFieldVisible('area') ? _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.areas,
      "item-value": "id",
      "item-text": "name",
      "label": "Область права",
      "clearable": true
    },
    model: {
      value: _vm.form.area,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "area", $$v);
      },
      expression: "form.area"
    }
  }), _vm.form.errors.has('area') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('area')) + " ")]) : _vm._e()], 1) : _vm._e()], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Сумма договора",
      "disabled": !_vm.formFields.amount.isEnabled(_vm.user)
    },
    model: {
      value: _vm.form.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "amount", $$v);
      },
      expression: "form.amount"
    }
  }), _vm.form.errors.has('amount') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('amount')) + " ")]) : _vm._e()], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.form.earnedAmount,
      "disabled": "disabled",
      "label": "Освоенная сумма"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.payedAmount,
      "disabled": "disabled",
      "label": "Остаток cуммы"
    }
  })], 1)], 1), _c('v-row', [_vm.isFieldVisible('lawyer') ? _c('v-col', [_c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.lawyers,
      "label": "Юрист",
      "item-value": "id",
      "item-text": "fullName",
      "clearable": true,
      "disabled": !_vm.formFields.lawyer.isEnabled(_vm.user)
    },
    model: {
      value: _vm.form.lawyer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lawyer", $$v);
      },
      expression: "form.lawyer"
    }
  }), _vm.form.errors.has('lawyer') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('lawyer')) + " ")]) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "% бонус"
    },
    model: {
      value: _vm.form.lawyerBonus,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lawyerBonus", $$v);
      },
      expression: "form.lawyerBonus"
    }
  }), _vm.form.errors.has('lawyerBonus') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('lawyerBonus')) + " ")]) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm.isFieldVisible('additionalLawyer') ? _c('v-col', [_c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.lawyers,
      "label": "Доп. юрист",
      "item-value": "id",
      "item-text": "fullName",
      "clearable": true,
      "disabled": !_vm.formFields.lawyer.isEnabled(_vm.user)
    },
    model: {
      value: _vm.form.additionalLawyer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "additionalLawyer", $$v);
      },
      expression: "form.additionalLawyer"
    }
  }), _vm.form.errors.has('additionalLawyer') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('additionalLawyer')) + " ")]) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "% бонус"
    },
    model: {
      value: _vm.form.additionalLawyerBonus,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "additionalLawyerBonus", $$v);
      },
      expression: "form.additionalLawyerBonus"
    }
  }), _vm.form.errors.has('additionalLawyerBonus') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('additionalLawyerBonus')) + " ")]) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm.isFieldVisible('lawyerAssistant') ? _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.lawyers,
      "label": "Помощник юриста",
      "item-value": "id",
      "item-text": "fullName",
      "clearable": true,
      "disabled": !_vm.formFields.lawyer.isEnabled(_vm.user)
    },
    model: {
      value: _vm.form.lawyerAssistant,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lawyerAssistant", $$v);
      },
      expression: "form.lawyerAssistant"
    }
  }), _vm.form.errors.has('lawyerAssistant') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('lawyerAssistant')) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.isFieldVisible('isEmployeeControl') ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "chekbox__blue",
    attrs: {
      "label": "Контроль сотрудника"
    },
    model: {
      value: _vm.form.isEmployeeControl,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isEmployeeControl", $$v);
      },
      expression: "form.isEmployeeControl"
    }
  })], 1) : _vm._e()], 1), _c('v-row', [_vm.isFieldVisible('lawyerOGPA') ? _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.lawyersOGPA,
      "label": "Юрист ОГПиА",
      "item-value": "id",
      "item-text": "fullName",
      "clearable": true,
      "disabled": !_vm.formFields.lawyerOGPA.isEnabled(_vm.user)
    },
    model: {
      value: _vm.form.lawyerOGPA,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lawyerOGPA", $$v);
      },
      expression: "form.lawyerOGPA"
    }
  }), _vm.form.errors.has('lawyerOGPA') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('lawyerOGPA')) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.isFieldVisible('lawyerOGPA') ? _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.lawyersOGPA,
      "label": "Доп. юрист ОГПиА",
      "item-value": "id",
      "item-text": "fullName",
      "clearable": true,
      "disabled": !_vm.formFields.lawyerOGPA.isEnabled(_vm.user)
    },
    model: {
      value: _vm.form.additionalLawyerOGPA,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "additionalLawyerOGPA", $$v);
      },
      expression: "form.additionalLawyerOGPA"
    }
  }), _vm.form.errors.has('additionalLawyerOGPA') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('additionalLawyerOGPA')) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.isFieldVisible('lawyerOGPA') ? _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.lawyersOGPA,
      "label": "Помощник юриста ОГПиА",
      "item-value": "id",
      "item-text": "fullName",
      "clearable": true,
      "disabled": !_vm.formFields.lawyerOGPA.isEnabled(_vm.user)
    },
    model: {
      value: _vm.form.lawyerOGPAAssistant,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lawyerOGPAAssistant", $$v);
      },
      expression: "form.lawyerOGPAAssistant"
    }
  }), _vm.form.errors.has('lawyerOGPAAssistant') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('lawyerOGPAAssistant')) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.isFieldVisible('isPaymentOGPA') ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "chekbox__blue",
    attrs: {
      "label": "Нагрузка ОГПиА"
    },
    model: {
      value: _vm.form.isPaymentOGPA,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isPaymentOGPA", $$v);
      },
      expression: "form.isPaymentOGPA"
    }
  })], 1) : _vm._e()], 1), _c('v-row', [_vm.isFieldVisible('lawyerEPO') ? _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.lawyersEPO,
      "label": "Юрист ЭПО",
      "item-value": "id",
      "item-text": "fullName",
      "clearable": true,
      "disabled": !_vm.formFields.lawyerEPO.isEnabled(_vm.user)
    },
    model: {
      value: _vm.form.lawyerEPO,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lawyerEPO", $$v);
      },
      expression: "form.lawyerEPO"
    }
  }), _vm.form.errors.has('lawyerEPO') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('lawyerEPO')) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.isFieldVisible('additionalLawyerEPO') ? _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.lawyersEPO,
      "label": "Подача документов в суд (юрист ЭПО)",
      "item-value": "id",
      "item-text": "fullName",
      "clearable": true,
      "disabled": !_vm.formFields.lawyerEPO.isEnabled(_vm.user)
    },
    model: {
      value: _vm.form.additionalLawyerEPO,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "additionalLawyerEPO", $$v);
      },
      expression: "form.additionalLawyerEPO"
    }
  }), _vm.form.errors.has('additionalLawyerEPO') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('additionalLawyerEPO')) + " ")]) : _vm._e()], 1) : _vm._e()], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Клиент",
      "disabled": !_vm.formFields.userName.isEnabled(_vm.user)
    },
    model: {
      value: _vm.form.userName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "userName", $$v);
      },
      expression: "form.userName"
    }
  }), _vm.form.errors.has('userName') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('userName')) + " ")]) : _vm._e()], 1), _vm.isFieldVisible('userPhone') && _vm.$_.get(_vm.page, 'contract.client.userPhone') ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.contract.client.userPhone,
      "label": "Телефон",
      "disabled": ""
    }
  })], 1) : _vm._e(), _vm.isFieldVisible('userPhone') && _vm.$_.get(_vm.page, 'contract.client.userPhone') ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('a', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "href": "tel:".concat(_vm.contract.client.userPhone)
    }
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "outlined": "",
      "color": "green"
    }
  }, [_c('v-icon', {
    staticStyle: {
      "margin": "0"
    },
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-phone")])], 1)], 1)]) : _vm._e(), _vm.isFieldVisible('userPhone') ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '+7 (###) ###-##-## #',
      expression: "'+7 (###) ###-##-## #'"
    }],
    attrs: {
      "label": "Телефон"
    },
    model: {
      value: _vm.form.clientPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "clientPhone", $$v);
      },
      expression: "form.clientPhone"
    }
  }), _vm.form.errors.has('clientPhone') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('clientPhone')) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.isFieldVisible('userPhone') && _vm.$_.get(_vm.page, 'contract.clientPhone') ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('a', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "href": "tel:".concat(_vm.contract.clientPhone)
    }
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "outlined": "",
      "color": "green"
    }
  }, [_c('v-icon', {
    staticStyle: {
      "margin": "0"
    },
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-phone")])], 1)], 1)]) : _vm._e(), _vm.isFieldVisible('additionalPhone') && _vm.$_.get(_vm.page, 'contract.client.additionalPhone') ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.contract.client.additionalPhone,
      "label": "Доп. телефон",
      "disabled": ""
    }
  })], 1) : _vm._e(), _vm.isFieldVisible('additionalPhone') && _vm.$_.get(_vm.page, 'contract.client.additionalPhone') ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('a', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "href": "tel:".concat(_vm.contract.client.additionalPhone)
    }
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "outlined": "",
      "color": "green"
    }
  }, [_c('v-icon', {
    staticStyle: {
      "margin": "0"
    },
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-phone")])], 1)], 1)]) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Email",
      "disabled": !_vm.formFields.clientEmail.isEnabled(_vm.user)
    },
    model: {
      value: _vm.form.clientEmail,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "clientEmail", $$v);
      },
      expression: "form.clientEmail"
    }
  }), _vm.form.errors.has('clientEmail') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('clientEmail')) + " ")]) : _vm._e()], 1), _vm.isFieldVisible('userEmail') && _vm.$_.get(_vm.page, 'contract.client.userEmail') ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.contract.client.userEmail,
      "label": "Email",
      "disabled": ""
    }
  })], 1) : _vm._e(), _vm.isFieldVisible('userPhone') && _vm.$_.get(_vm.page, 'contract.client.id') ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "to": "/clients/".concat(_vm.page.contract.client.id, "/edit"),
      "small": "",
      "outlined": "",
      "color": "green"
    }
  }, [_vm._v(" Перейти ")])], 1) : _vm._e()], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Доверитель",
      "disabled": !_vm.formFields.principal.isEnabled(_vm.user)
    },
    model: {
      value: _vm.form.principal,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "principal", $$v);
      },
      expression: "form.principal"
    }
  }), _vm.form.errors.has('principal') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('principal')) + " ")]) : _vm._e()], 1), _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "label": "Офис",
      "items": _vm.offices,
      "item-value": "id",
      "item-text": "name",
      "disabled": !_vm.$helper.permission.canCreateContract(_vm.user),
      "clearable": true
    },
    model: {
      value: _vm.form.office,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "office", $$v);
      },
      expression: "form.office"
    }
  }), _vm.form.errors.has('office') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('office')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_vm._l(_vm.form.contractFlows, function (flow, key) {
    return _c('div', {
      key: flow.id
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', [_c('DateField', {
      attrs: {
        "form": _vm.form,
        "disabled": Boolean(flow.id),
        "label": "Дата поступления заявления",
        "fieldPath": "applicationDate"
      },
      model: {
        value: flow.applicationDate,
        callback: function callback($$v) {
          _vm.$set(flow, "applicationDate", $$v);
        },
        expression: "flow.applicationDate"
      }
    })], 1), _c('v-col', [_c('DateField', {
      attrs: {
        "form": _vm.form,
        "disabled": Boolean(flow.id),
        "label": "Дата направления ответа",
        "fieldPath": "answerDate"
      },
      model: {
        value: flow.answerDate,
        callback: function callback($$v) {
          _vm.$set(flow, "answerDate", $$v);
        },
        expression: "flow.answerDate"
      }
    })], 1), _c('v-col', [_c('DateField', {
      attrs: {
        "form": _vm.form,
        "disabled": Boolean(flow.id),
        "label": "Дата возврата денежных средств",
        "fieldPath": "moneyBackDate"
      },
      model: {
        value: flow.moneyBackDate,
        callback: function callback($$v) {
          _vm.$set(flow, "moneyBackDate", $$v);
        },
        expression: "flow.moneyBackDate"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-checkbox', {
      staticClass: "chekbox__white",
      attrs: {
        "label": "Иное заявление"
      },
      model: {
        value: flow.otherApplication,
        callback: function callback($$v) {
          _vm.$set(flow, "otherApplication", $$v);
        },
        expression: "flow.otherApplication"
      }
    }, [_vm._v(" > ")])], 1), _c('v-col', {
      attrs: {
        "cols": "1"
      }
    }, [key + 1 == _vm.form.contractFlows.length ? _c('v-btn', {
      attrs: {
        "text": "",
        "color": "success",
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.addNewFlow();
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-plus-box-outline")])], 1) : _vm._e()], 1), _c('v-col', {
      attrs: {
        "cols": "1"
      }
    }, [!flow.id ? _c('v-btn', {
      attrs: {
        "text": "",
        "color": "red",
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.removeFlow(key);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-minus-box-outline")])], 1) : _vm._e()], 1)], 1), flow.id ? _c('v-row', {
      staticClass: "flow_comment text-"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(flow.user.fullName) + ": ")]), _c('v-col', [_vm._v(" " + _vm._s(flow.comment) + " ")])], 1) : _vm._e(), !flow.id ? _c('v-row', [_c('v-col', [_c('v-textarea', {
      attrs: {
        "label": "Комментарий",
        "rows": "2",
        "auto-grow": ""
      },
      model: {
        value: flow.comment,
        callback: function callback($$v) {
          _vm.$set(flow, "comment", $$v);
        },
        expression: "flow.comment"
      }
    })], 1)], 1) : _vm._e()], 1);
  }), !_vm.form.contractFlows || _vm.form.contractFlows.length < 1 ? _c('div', [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.addNewFlow();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus-box-outline")]), _vm._v(" Добавить действие ")], 1)], 1) : _vm._e()], 2), _vm.isFieldVisible('sentToOKK') ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('ContractAction', {
    attrs: {
      "actions": _vm.form.sentToOkkActions,
      "label": "Передано в ОКК"
    },
    on: {
      "deleteAction": function deleteAction($event) {
        return _vm.deleteSentToOkkActions($event);
      }
    }
  })], 1) : _vm._e()], 1), _c('v-row', [_vm.isFieldVisible('actions') ? _c('v-col', [_c('v-textarea', {
    attrs: {
      "label": "Действие по договору",
      "rows": "2",
      "disabled": !_vm.formFields.actions.isEnabled(_vm.user),
      "auto-grow": ""
    },
    model: {
      value: _vm.form.actions,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "actions", $$v);
      },
      expression: "form.actions"
    }
  }), _vm.form.errors.has('actions') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('actions')) + " ")]) : _vm._e()], 1) : _vm._e()], 1), _c('v-row', [_vm.isFieldVisible('comment') ? _c('v-col', [_c('v-textarea', {
    attrs: {
      "label": "Примечание",
      "rows": "2",
      "disabled": !_vm.formFields.comment.isEnabled(_vm.user),
      "auto-grow": ""
    },
    model: {
      value: _vm.form.comment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  }), _vm.form.errors.has('comment') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('comment')) + " ")]) : _vm._e()], 1) : _vm._e()], 1), _c('v-row', [_vm.isFieldVisibleOnlyFor('commentForBuhgalter') ? _c('v-col', [_c('v-textarea', {
    attrs: {
      "label": "Комментарий (бухгалтер)",
      "rows": "2",
      "disabled": !_vm.formFields.commentForBuhgalter.isEnabled(_vm.user),
      "auto-grow": ""
    },
    model: {
      value: _vm.form.commentForBuhgalter,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "commentForBuhgalter", $$v);
      },
      expression: "form.commentForBuhgalter"
    }
  }), _vm.form.errors.has('commentForBuhgalter') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('commentForBuhgalter')) + " ")]) : _vm._e()], 1) : _vm._e()], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "3"
    }
  }, [_c('v-row', [false && _vm.isFieldVisibleOnlyFor('buhgalter') ? _c('v-col', [_c('v-checkbox', {
    staticClass: "chekbox__blue",
    attrs: {
      "disabled": !_vm.formFields.sentForBuhgalter.isEnabled(_vm.user),
      "label": "Бухгалтер"
    },
    on: {
      "change": function change($event) {
        return _vm.handleChangeSentForBuhgalter();
      }
    },
    model: {
      value: _vm.form.isSentForBuhgalter,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isSentForBuhgalter", $$v);
      },
      expression: "form.isSentForBuhgalter"
    }
  })], 1) : _vm._e(), false ? _c('v-col', [_c('v-checkbox', {
    staticClass: "chekbox__blue",
    attrs: {
      "disabled": !_vm.formFields.isBezNal.isEnabled(_vm.user),
      "label": "Б/н"
    },
    model: {
      value: _vm.form.isBezNal,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isBezNal", $$v);
      },
      expression: "form.isBezNal"
    }
  })], 1) : _vm._e()], 1), _c('v-row', [_vm.isFieldVisible('files') ? _c('v-col', [_c('div', {
    staticClass: "title mt-2 mb-3"
  }, [_vm._v("Файлы:")]), _c('div', [_c('v-file-input', {
    attrs: {
      "chips": "",
      "counter": "",
      "multiple": "",
      "show-size": "",
      "label": "Добавить файлы",
      "rules": _vm.fileRules
    },
    model: {
      value: _vm.files,
      callback: function callback($$v) {
        _vm.files = $$v;
      },
      expression: "files"
    }
  }), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.fileDirectories,
      "item-value": "id",
      "item-text": "name",
      "label": "Папка"
    },
    model: {
      value: _vm.fileUploadForm.directory.id,
      callback: function callback($$v) {
        _vm.$set(_vm.fileUploadForm.directory, "id", $$v);
      },
      expression: "fileUploadForm.directory.id"
    }
  }), _vm.fileUploadForm.errors.has('directory') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.fileUploadForm.errors.get('directory')) + " ")]) : _vm._e()], 1), (_vm$page = _vm.page) !== null && _vm$page !== void 0 && (_vm$page$contract = _vm$page.contract) !== null && _vm$page$contract !== void 0 && _vm$page$contract.id ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.files,
      expression: "files"
    }],
    attrs: {
      "color": "green",
      "disabled": _vm.fileUploading
    },
    on: {
      "click": function click($event) {
        return _vm.uploadFiles();
      }
    }
  }, [_vm._v(" Сохранить "), _vm.fileUploading ? _c('v-progress-circular', {
    attrs: {
      "size": "16",
      "width": "2",
      "indeterminate": ""
    }
  }) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1), _vm.form.files !== undefined && Array.isArray(_vm.form.files) && _vm.form.files.length ? _c('div', {
    staticClass: "files mt-3"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.filesWithoutDirectories, function (file) {
    return _c('v-col', {
      key: file.id,
      staticClass: "ma-1",
      attrs: {
        "cols": "auto"
      }
    }, [_c('drag', {
      staticClass: "drag",
      attrs: {
        "transfer-data": {
          fileId: file.id
        }
      }
    }, [_c('v-chip', [_vm.fileLoading[file.id] ? _c('v-progress-circular', {
      attrs: {
        "size": "16",
        "width": "2",
        "indeterminate": ""
      }
    }) : _vm._e(), _vm.$helper.permission.canSaveContract(_vm.user) ? _c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "right": "",
        "color": "red"
      },
      on: {
        "click": function click($event) {
          return _vm.removeFile(file);
        }
      }
    }, [_vm._v(" mdi-close ")]) : _vm._e(), _c('a', {
      attrs: {
        "href": "javascript:void(0)"
      },
      on: {
        "click": function click($event) {
          return _vm.downloadFile(file);
        }
      }
    }, [_vm._v(" " + _vm._s(file.name) + " ")])], 1), _c('div', {
      staticClass: "font-size__small"
    }, [_vm._v(" " + _vm._s(_vm.$moment(file.createdAt).dateTimeFormat()) + " ")])], 1)], 1);
  }), 1)], 1) : _vm._e(), _c('div', {
    staticClass: "fileDirectories mt-2 pt-2"
  }, _vm._l(_vm.fileDirectories, function (dir) {
    return _c('div', {
      key: dir.id
    }, [_c('drop', {
      staticClass: "drop",
      on: {
        "drop": function drop(data) {
          return _vm.handleFileDirectoryDrop(dir.id, data);
        }
      }
    }, [_c('div', [_c('div', {
      staticClass: "directory-name",
      on: {
        "click": function click($event) {
          return _vm.handleDirectoryCLick(dir);
        }
      }
    }, [_c('v-row', {
      attrs: {
        "dense": "",
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-chip', {
      attrs: {
        "outlined": ""
      }
    }, [!dir.opened ? _c('v-icon', {
      attrs: {
        "color": "red"
      }
    }, [_vm._v(" mdi-folder-outline ")]) : _vm._e(), dir.opened ? _c('v-icon', {
      attrs: {
        "color": "red"
      }
    }, [_vm._v(" mdi-folder-open-outline ")]) : _vm._e(), _c('span', {
      domProps: {
        "innerHTML": _vm._s(_vm.getDirectorySize(dir))
      }
    })], 1)], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(dir.name) + " ")])], 1)], 1), dir.opened ? _c('div', _vm._l(_vm.form.files, function (file) {
      var _file$directory;
      return _c('div', {
        key: file.id,
        staticClass: "files"
      }, [((_file$directory = file.directory) === null || _file$directory === void 0 ? void 0 : _file$directory.id) === dir.id ? _c('drag', {
        staticClass: "drag",
        attrs: {
          "transfer-data": {
            fileId: file.id
          }
        }
      }, [_c('v-chip', [_vm.fileLoading[file.id] ? _c('v-progress-circular', {
        attrs: {
          "size": "16",
          "width": "2",
          "indeterminate": ""
        }
      }) : _vm._e(), _vm.$helper.permission.canSaveContract(_vm.user) ? _c('v-icon', {
        staticClass: "mr-2",
        attrs: {
          "right": "",
          "color": "red"
        },
        on: {
          "click": function click($event) {
            return _vm.removeFile(file);
          }
        }
      }, [_vm._v(" mdi-close ")]) : _vm._e(), _c('a', {
        attrs: {
          "href": "javascript:void(0)"
        },
        on: {
          "click": function click($event) {
            return _vm.downloadFile(file);
          }
        }
      }, [_vm._v(" " + _vm._s(file.name) + " ")])], 1), _c('div', {
        staticClass: "font-size__small"
      }, [_vm._v(" " + _vm._s(_vm.$moment(file.createdAt).dateTimeFormat()) + " ")])], 1) : _vm._e()], 1);
    }), 0) : _vm._e()])])], 1);
  }), 0)]) : _vm._e()], 1)], 1)], 1), _vm.form.errors.any() ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" Исправьте ошибки ")]) : _vm._e(), _vm.form.errors.has('error') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('error')) + " ")]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.form.busy,
      expression: "!form.busy"
    }]
  }, [_vm.$helper.permission.canSaveContract(_vm.user) ? _c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.hasChanges,
      expression: "form.hasChanges"
    }],
    attrs: {
      "large": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        _vm.contract && _vm.contract.id ? _vm.update(false) : _vm.save(false);
      }
    }
  }, [_vm._v(" Сохранить ")]) : _vm._e(), _vm.$helper.permission.canSaveContract(_vm.user) ? _c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.hasChanges,
      expression: "form.hasChanges"
    }],
    staticClass: "ml-4",
    attrs: {
      "large": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        _vm.contract && _vm.contract.id ? _vm.update(true) : _vm.save(false);
      }
    }
  }, [_vm._v(" Сохранить и закрыть ")]) : _vm._e()], 1), _c('v-progress-circular', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.busy,
      expression: "form.busy"
    }],
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  })], 1)])], 1) : _vm._e(), _vm.contract && _vm.contract.id ? _c('v-expansion-panels', {
    staticClass: "mt-5 task-expansion"
  }, [_vm.taskForms.legalExpert ? _c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('v-card-title', {
    staticClass: "px-1 py-2"
  }, [_vm._v("Задание в отдел исполнения")])], 1), _c('v-expansion-panel-content', [_c('v-row', [_c('v-col', [_c('MultiField', {
    staticClass: "mb-5",
    attrs: {
      "field-title": "Общее описание ситуации клиента",
      "form-field": (_vm$taskForms$legalEx = _vm.taskForms.legalExpert) === null || _vm$taskForms$legalEx === void 0 ? void 0 : _vm$taskForms$legalEx.documents,
      "field-initial-state": (_vm$initialState$task = _vm.initialState.taskLegalExpert) === null || _vm$initialState$task === void 0 ? void 0 : _vm$initialState$task.documents
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('MultiField', {
    staticClass: "mb-5",
    attrs: {
      "field-title": "Цель клиента",
      "form-field": (_vm$taskForms$legalEx2 = _vm.taskForms.legalExpert) === null || _vm$taskForms$legalEx2 === void 0 ? void 0 : _vm$taskForms$legalEx2.clientPosition,
      "field-initial-state": (_vm$initialState$task2 = _vm.initialState.taskLegalExpert) === null || _vm$initialState$task2 === void 0 ? void 0 : _vm$initialState$task2.clientPosition
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('MultiField', {
    staticClass: "mb-5",
    attrs: {
      "field-title": "Оговоренная стратегия (на что ориентирован клиент) *",
      "form-field": (_vm$taskForms$legalEx3 = _vm.taskForms.legalExpert) === null || _vm$taskForms$legalEx3 === void 0 ? void 0 : _vm$taskForms$legalEx3.clientOrientation,
      "field-initial-state": (_vm$initialState$task3 = _vm.initialState.taskLegalExpert) === null || _vm$initialState$task3 === void 0 ? void 0 : _vm$initialState$task3.clientOrientation
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('MultiField', {
    staticClass: "mb-5",
    attrs: {
      "field-title": "Действия эксперта",
      "form-field": (_vm$taskForms$legalEx4 = _vm.taskForms.legalExpert) === null || _vm$taskForms$legalEx4 === void 0 ? void 0 : _vm$taskForms$legalEx4.expertActions,
      "field-initial-state": (_vm$initialState$task4 = _vm.initialState.taskLegalExpert) === null || _vm$initialState$task4 === void 0 ? void 0 : _vm$initialState$task4.expertActions
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('DateField', {
    attrs: {
      "form": _vm.form,
      "label": "Дата готовности документов",
      "fieldPath": "taskLegalExpert.readyDate"
    },
    model: {
      value: _vm.taskForms.legalExpert.readyDate,
      callback: function callback($$v) {
        _vm.$set(_vm.taskForms.legalExpert, "readyDate", $$v);
      },
      expression: "taskForms.legalExpert.readyDate"
    }
  }), _vm.taskForms.legalExpert.errors.has('readyDate') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.taskForms.legalExpert.errors.get('readyDate')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('DateField', {
    attrs: {
      "form": _vm.form,
      "label": "Дата подачи (процессуальные сроки)",
      "fieldPath": "taskLegalExpert.sentDate"
    },
    model: {
      value: _vm.taskForms.legalExpert.sentDate,
      callback: function callback($$v) {
        _vm.$set(_vm.taskForms.legalExpert, "sentDate", $$v);
      },
      expression: "taskForms.legalExpert.sentDate"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('MultiField', {
    staticClass: "mb-5",
    attrs: {
      "field-title": "Действия представителя",
      "form-field": (_vm$taskForms$legalEx5 = _vm.taskForms.legalExpert) === null || _vm$taskForms$legalEx5 === void 0 ? void 0 : _vm$taskForms$legalEx5.representativeActions,
      "field-initial-state": (_vm$initialState$task5 = _vm.initialState.taskLegalExpert) === null || _vm$initialState$task5 === void 0 ? void 0 : _vm$initialState$task5.representativeActions
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('MultiField', {
    staticClass: "mb-5",
    attrs: {
      "field-title": "Заполняет руководитель отдела исполнения",
      "form-field": (_vm$taskForms$legalEx6 = _vm.taskForms.legalExpert) === null || _vm$taskForms$legalEx6 === void 0 ? void 0 : _vm$taskForms$legalEx6.executionDepartmentHead,
      "field-initial-state": (_vm$initialState$task6 = _vm.initialState.taskLegalExpert) === null || _vm$initialState$task6 === void 0 ? void 0 : _vm$initialState$task6.executionDepartmentHead
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('DateField', {
    attrs: {
      "form": _vm.form,
      "label": "Сроки исполнения",
      "fieldPath": "taskLegalExpert.executionTerms"
    },
    model: {
      value: _vm.taskForms.legalExpert.executionTerms,
      callback: function callback($$v) {
        _vm.$set(_vm.taskForms.legalExpert, "executionTerms", $$v);
      },
      expression: "taskForms.legalExpert.executionTerms"
    }
  })], 1)], 1), _c('h3', [_vm._v("Подпись юриста")]), _c('div', {
    staticClass: "mt-2 mb-5"
  }, [_vm.user.role == 'LAWYER' || _vm.user.role == 'LAWYER_OGPIA' || _vm.user.role == 'LAWYER_EPO' ? _c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "small": "",
      "outlined": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        return _vm.signTaskLegalExpert();
      }
    }
  }, [_c('div', [_vm._v("Подписать")])])], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('Signatures', {
    attrs: {
      "signatures": _vm.form.signaturesTaskLegalExpertLawyer
    }
  })], 1)], 1)], 1), _c('h3', [_vm._v("Подпись руководителя")]), _c('div', {
    staticClass: "mt-2 mb-5"
  }, [_vm.user.role == 'LAWYER_MANAGER' || _vm.user.role == 'MANAGER_EPO' || _vm.user.role == 'ADMIN' || _vm.user.role == 'MANAGER_OGPIA' || _vm.user.role == 'MANAGER_OKK' ? _c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "small": "",
      "outlined": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        return _vm.signTaskLegalExpert();
      }
    }
  }, [_c('div', [_vm._v("Подписать")])])], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('Signatures', {
    attrs: {
      "signatures": _vm.form.signaturesTaskLegalExpertManager
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e(), _vm.taskForms.civilianRepr ? _c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('v-card-title', {
    staticClass: "px-1 py-2"
  }, [_vm._v("Задание в отдел гражданских представителей и адвокатов ")])], 1), _c('v-expansion-panel-content', [_c('v-checkbox', {
    attrs: {
      "label": "Договор (копия всех листов договора / чеки отдельно) *"
    },
    model: {
      value: _vm.taskForms.civilianRepr.docContract,
      callback: function callback($$v) {
        _vm.$set(_vm.taskForms.civilianRepr, "docContract", $$v);
      },
      expression: "taskForms.civilianRepr.docContract"
    }
  }, [_vm._v(" > ")]), _c('v-checkbox', {
    attrs: {
      "label": "Приложение №1 к Договору *"
    },
    model: {
      value: _vm.taskForms.civilianRepr.docApplication,
      callback: function callback($$v) {
        _vm.$set(_vm.taskForms.civilianRepr, "docApplication", $$v);
      },
      expression: "taskForms.civilianRepr.docApplication"
    }
  }, [_vm._v(" > ")]), _c('v-checkbox', {
    attrs: {
      "label": "Доверенность(нотариально заверенная копия для суда) *"
    },
    model: {
      value: _vm.taskForms.civilianRepr.docProxy,
      callback: function callback($$v) {
        _vm.$set(_vm.taskForms.civilianRepr, "docProxy", $$v);
      },
      expression: "taskForms.civilianRepr.docProxy"
    }
  }, [_vm._v(" > ")]), _c('v-checkbox', {
    attrs: {
      "label": "Транспортные"
    },
    model: {
      value: _vm.taskForms.civilianRepr.docTransport,
      callback: function callback($$v) {
        _vm.$set(_vm.taskForms.civilianRepr, "docTransport", $$v);
      },
      expression: "taskForms.civilianRepr.docTransport"
    }
  }, [_vm._v(" > ")]), _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "label": "Копии иных документов",
      "rows": "3",
      "auto-grow": ""
    },
    model: {
      value: _vm.taskForms.civilianRepr.docCopies,
      callback: function callback($$v) {
        _vm.$set(_vm.taskForms.civilianRepr, "docCopies", $$v);
      },
      expression: "taskForms.civilianRepr.docCopies"
    }
  }), _vm.taskForms.civilianRepr.errors.has('docCopies') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.taskForms.civilianRepr.errors.get('docCopies')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "label": "Ситуация клиента *",
      "rows": "2",
      "auto-grow": ""
    },
    model: {
      value: _vm.taskForms.civilianRepr.clientSituation,
      callback: function callback($$v) {
        _vm.$set(_vm.taskForms.civilianRepr, "clientSituation", $$v);
      },
      expression: "taskForms.civilianRepr.clientSituation"
    }
  }), _vm.taskForms.civilianRepr.errors.has('clientSituation') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.taskForms.civilianRepr.errors.get('clientSituation')) + " ")]) : _vm._e()], 1), _vm._v("s ")], 1), _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "label": "Цель клиента по данному Договору (не предмет Договора!) *",
      "rows": "2",
      "auto-grow": ""
    },
    model: {
      value: _vm.taskForms.civilianRepr.clientAim,
      callback: function callback($$v) {
        _vm.$set(_vm.taskForms.civilianRepr, "clientAim", $$v);
      },
      expression: "taskForms.civilianRepr.clientAim"
    }
  }), _vm.taskForms.civilianRepr.errors.has('clientAim') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.taskForms.civilianRepr.errors.get('clientAim')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "label": "На что ориентировал Клиента юрист по договору",
      "hint": "при ориентировании клиента на сроки: сроки созвона с клиентом, срок подготовки документов, дополнительные документы сверх предмета договора, доп действия по Договору",
      "rows": "2",
      "auto-grow": ""
    },
    model: {
      value: _vm.taskForms.civilianRepr.clientOrientation,
      callback: function callback($$v) {
        _vm.$set(_vm.taskForms.civilianRepr, "clientOrientation", $$v);
      },
      expression: "taskForms.civilianRepr.clientOrientation"
    }
  }), _vm.taskForms.civilianRepr.errors.has('clientOrientation') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.taskForms.civilianRepr.errors.get('clientOrientation')) + " ")]) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "mb-5 pb-3"
  }, [_vm._v(" *поля обязательные к заполнению. В случае не предоставления информации, представитель не приступает к исполнению Договора. "), _c('br'), _vm._v(" ВНИМАНИЕ!!! СРОК ПОДГОТОВКИ ДОКУМЕНТОВ 3-4 ДНЯ, ПРАВОВОЙ АНАЛИЗ 3-4 ДНЯ, ДОСУДЕБКА В ТЕЧЕНИИ 5 ТИ РАБОЧИХ ДНЕЙ. "), _c('br'), _vm._v(" *СРОЧНЫЙ ВЫЕЗД/СУД, ГДЕ НАЗНАЧЕНА ДАТА С/З, ТОЛЬКО С СОГЛАСОВАНИЯ С РУКОВОДИТЕЛЕМ/ ЗАМЕСТИТЕЛЕМ ОТДЕЛА ПРЕДСТАВИТЕЛЕЙ. "), _c('br'), _vm._v(" *Дела, в отдел представителей, передаются только с резолюцией руководителя юридического отдела "), _c('br'), _vm._v(" *Без полной оплаты договоры принимаются только с резолюцией директора и руководителя юр.отдела ")]), _c('h3', [_vm._v("Подпись юриста")]), _c('div', {
    staticClass: "mb-5"
  }, [_vm.user.role == 'LAWYER' || _vm.user.role == 'LAWYER_OGPIA' || _vm.user.role == 'LAWYER_EPO' ? _c('v-row', {
    staticClass: "d-none"
  }, [_c('v-col', [_c('v-btn', {
    attrs: {
      "small": "",
      "outlined": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        return _vm.signTaskCivilianRepr();
      }
    }
  }, [_c('div', [_vm._v("Подписать")])])], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('Signatures', {
    attrs: {
      "signatures": _vm.form.signaturesTaskCivilianReprLawyer
    }
  })], 1)], 1)], 1), _c('h3', [_vm._v("Подпись руководителя")]), _c('div', {
    staticClass: "mb-5"
  }, [_vm.user.role == 'LAWYER_MANAGER' || _vm.user.role == 'ADMIN' || _vm.user.role == 'MANAGER_EPO' || _vm.user.role == 'MANAGER_OKK' ? _c('v-row', {
    staticClass: "d-none"
  }, [_c('v-col', [_c('v-btn', {
    attrs: {
      "small": "",
      "outlined": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        return _vm.signTaskCivilianRepr();
      }
    }
  }, [_c('div', [_vm._v("Подписать")])])], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('Signatures', {
    attrs: {
      "signatures": _vm.form.signaturesTaskCivilianReprManager
    }
  })], 1)], 1)], 1), _c('h3', [_vm._v("Заполняет руководитель отдела представителей")]), _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "label": "Необходимая работа, срок исполнения",
      "rows": "2",
      "auto-grow": ""
    },
    model: {
      value: _vm.taskForms.civilianRepr.workNeeded,
      callback: function callback($$v) {
        _vm.$set(_vm.taskForms.civilianRepr, "workNeeded", $$v);
      },
      expression: "taskForms.civilianRepr.workNeeded"
    }
  }), _vm.taskForms.civilianRepr.errors.has('workNeeded') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.taskForms.civilianRepr.errors.get('workNeeded')) + " ")]) : _vm._e()], 1)], 1), _c('h3', [_vm._v("Подпись руководителя")]), _c('div', {
    staticClass: "mb-5"
  }, [_vm.user.role == 'MANAGER_OGPIA' ? _c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "small": "",
      "outlined": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        return _vm.signTaskCivilianRepr();
      }
    }
  }, [_c('div', [_vm._v("Подписать")])])], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('Signatures', {
    attrs: {
      "signatures": _vm.form.signaturesTaskCivilianReprRepresentativeManager
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e(), _vm.taskForms.addLegalExpert ? _c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('v-card-title', {
    staticClass: "px-1 py-2"
  }, [_vm._v(" Дополнительное задание в экспертно-правовой отдел ")])], 1), _c('v-expansion-panel-content', [_c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "label": "Документы",
      "rows": "3",
      "auto-grow": ""
    },
    model: {
      value: _vm.taskForms.addLegalExpert.documents,
      callback: function callback($$v) {
        _vm.$set(_vm.taskForms.addLegalExpert, "documents", $$v);
      },
      expression: "taskForms.addLegalExpert.documents"
    }
  }), _vm.taskForms.addLegalExpert.errors.has('documents') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.taskForms.addLegalExpert.errors.get('documents')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "label": "Правовая позиция клиента",
      "rows": "2",
      "auto-grow": ""
    },
    model: {
      value: _vm.taskForms.addLegalExpert.clientPosition,
      callback: function callback($$v) {
        _vm.$set(_vm.taskForms.addLegalExpert, "clientPosition", $$v);
      },
      expression: "taskForms.addLegalExpert.clientPosition"
    }
  }), _vm.taskForms.addLegalExpert.errors.has('clientPosition') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.taskForms.addLegalExpert.errors.get('clientPosition')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "label": "На что ориентировал Клиента юрист по Договору *",
      "rows": "2",
      "auto-grow": ""
    },
    model: {
      value: _vm.taskForms.addLegalExpert.clientOrientation,
      callback: function callback($$v) {
        _vm.$set(_vm.taskForms.addLegalExpert, "clientOrientation", $$v);
      },
      expression: "taskForms.addLegalExpert.clientOrientation"
    }
  }), _vm.taskForms.addLegalExpert.errors.has('clientOrientation') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.taskForms.addLegalExpert.errors.get('clientOrientation')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('DateField', {
    attrs: {
      "form": _vm.form,
      "label": "Дата готовности пакета",
      "fieldPath": "addTaskLegalExpert.readyDate"
    },
    model: {
      value: _vm.taskForms.addLegalExpert.readyDate,
      callback: function callback($$v) {
        _vm.$set(_vm.taskForms.addLegalExpert, "readyDate", $$v);
      },
      expression: "taskForms.addLegalExpert.readyDate"
    }
  })], 1)], 1), _c('h3', [_vm._v("Подпись юриста")]), _c('div', {
    staticClass: "mb-5"
  }, [_vm.user.role == 'LAWYER' || _vm.user.role == 'LAWYER_OGPIA' || _vm.user.role == 'LAWYER_EPO' ? _c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "small": "",
      "outlined": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        return _vm.signAddTaskLegalExpert();
      }
    }
  }, [_c('div', [_vm._v("Подписать")])])], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('Signatures', {
    attrs: {
      "signatures": _vm.form.signaturesAddTaskLegalExpertLawyer
    }
  })], 1)], 1)], 1), _c('h3', [_vm._v("Подпись руководителя")]), _c('div', {
    staticClass: "mb-5"
  }, [_vm.user.role == 'LAWYER_MANAGER' || _vm.user.role == 'MANAGER_EPO' || _vm.user.role == 'ADMIN' || _vm.user.role == 'MANAGER_OGPIA' || _vm.user.role == 'MANAGER_OKK' ? _c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "small": "",
      "outlined": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        return _vm.signAddTaskLegalExpert();
      }
    }
  }, [_c('div', [_vm._v("Подписать")])])], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('Signatures', {
    attrs: {
      "signatures": _vm.form.signaturesAddTaskLegalExpertManager
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.isFieldVisible('ContractAdditionalAgreement') && _vm.contract && _vm.contract.id ? _c('ContractAdditionalAgreement', {
    attrs: {
      "id": _vm.contract.id
    }
  }) : _vm._e(), _vm.isFieldVisible('Events') && _vm.contract && _vm.contract.id ? _c('Events', {
    attrs: {
      "id": _vm.contract.id,
      "types": _vm.page.eventTypes,
      "executors": _vm.page.executors
    },
    on: {
      "saved": _vm.onEventAdd
    }
  }) : _vm._e(), _vm.isFieldVisible('ContactChannels') && _vm.contract && _vm.contract.id ? _c('ContactChannels', {
    key: _vm.contactChannelsGridKey,
    attrs: {
      "id": _vm.contract.id
    }
  }) : _vm._e(), _vm.contract && _vm.$helper.permission.canSeeContractCalls(_vm.user) && _vm.$_.get(_vm.contract, 'client.userPhone') ? _c('ContractCalls', {
    attrs: {
      "title": "\u0418\u0441\u0445. \u0432\u044B\u0437\u043E\u0432\u044B \u0434\u043B\u044F \u043D\u043E\u043C\u0435\u0440\u0430: ".concat(_vm.contract.client.userPhone),
      "filter": {
        callingNumber: _vm.contract.client.userPhone.replace(/\D+/g, '')
      }
    }
  }) : _vm._e(), _vm.contract && _vm.$helper.permission.canSeeContractCalls(_vm.user) && _vm.$_.get(_vm.contract, 'client.userPhone') ? _c('ContractCalls', {
    attrs: {
      "title": "\u0412\u0445. \u0432\u044B\u0437\u043E\u0432\u044B \u0434\u043B\u044F \u043D\u043E\u043C\u0435\u0440\u0430: ".concat(_vm.contract.client.userPhone),
      "filter": {
        calledNumber: _vm.contract.client.userPhone.replace(/\D+/g, '')
      }
    }
  }) : _vm._e(), _vm.contract && _vm.$helper.permission.canSeeContractCalls(_vm.user) && _vm.contract.clientPhone ? _c('ContractCalls', {
    attrs: {
      "title": "\u0418\u0441\u0445. \u0432\u044B\u0437\u043E\u0432\u044B \u0434\u043B\u044F \u043D\u043E\u043C\u0435\u0440\u0430: ".concat(_vm.contract.clientPhone),
      "filter": {
        callingNumber: _vm.contract.clientPhone.replace(/\D+/g, '')
      }
    }
  }) : _vm._e(), _vm.contract && _vm.$helper.permission.canSeeContractCalls(_vm.user) && _vm.contract.clientPhone ? _c('ContractCalls', {
    attrs: {
      "title": "\u0412\u0445. \u0432\u044B\u0437\u043E\u0432\u044B \u0434\u043B\u044F \u043D\u043E\u043C\u0435\u0440\u0430: ".concat(_vm.contract.clientPhone),
      "filter": {
        calledNumber: (_vm$contract$clientPh = _vm.contract.clientPhone) === null || _vm$contract$clientPh === void 0 ? void 0 : _vm$contract$clientPh.replace(/\D+/g, '')
      }
    }
  }) : _vm._e(), _vm.isFieldVisible('ContractPayments') && _vm.contract && _vm.contract.id ? _c('ContractPaymentsGrid', {
    key: _vm.contractPaymentsKey,
    attrs: {
      "title": "Оплаты",
      "contract": _vm.form,
      "filter": {
        'contract.id': _vm.page.contract.id
      },
      "columns": _vm.$helper.contract__ContractPaymentColumns.getForRole(_vm.user.role)
    }
  }) : _vm._e(), _vm.isFieldVisible('ContractAdditionalPayments') && _vm.contract && _vm.contract.id ? _c('ContractAdditionalPayments', {
    attrs: {
      "contract": _vm.page.contract
    }
  }) : _vm._e(), _vm.isFieldVisible('ContractActs') && _vm.contract && _vm.contract.id ? _c('ContractActs', {
    attrs: {
      "id": _vm.contract.id,
      "contract": _vm.contract,
      "lawyers": _vm.lawyers,
      "lawyersOGPA": _vm.lawyersOGPA,
      "lawyersEPO": _vm.lawyersEPO
    }
  }) : _vm._e(), _vm.isFieldVisible('ContractComments') && _vm.contract && _vm.contract.id ? _c('ContractComments', {
    attrs: {
      "id": _vm.contract.id,
      "types": _vm.page.commentTypes,
      "users": _vm.page.users
    }
  }) : _vm._e(), _vm.isFieldVisible('NotificationsGrid') && _vm.contract && _vm.contract.id ? _c('NotificationsGrid', {
    attrs: {
      "id": _vm.contract.id,
      "users": _vm.page.users,
      "type": "Contract"
    }
  }) : _vm._e(), _vm.contract && _vm.contract.id ? _c('ContractVisits', {
    staticClass: "mb-5",
    attrs: {
      "id": _vm.contract.id
    }
  }) : _vm._e(), _vm.contract && _vm.contract.id ? _c('ContractRevisions', {
    staticClass: "mb-5",
    attrs: {
      "id": _vm.contract.id,
      "page": _vm.page
    }
  }) : _vm._e(), _c('v-bottom-sheet', {
    attrs: {
      "persistent": ""
    },
    model: {
      value: _vm.dialog.playRecord.modal,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog.playRecord, "modal", $$v);
      },
      expression: "dialog.playRecord.modal"
    }
  }, [_c('v-sheet', {
    staticClass: "text-center",
    attrs: {
      "height": "200px"
    }
  }, [_c('v-btn', {
    staticClass: "mt-6",
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": function click($event) {
        return _vm.closePlayAudio();
      }
    }
  }, [_vm._v("закрыть")]), _c('div', {
    staticClass: "py-3"
  }, [_vm.audioUrl ? _c('PlayRecord', {
    attrs: {
      "audioUrl": _vm.audioUrl
    }
  }) : _vm._e()], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500px"
    },
    model: {
      value: _vm.dialog.commentForBuhgalter,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog, "commentForBuhgalter", $$v);
      },
      expression: "dialog.commentForBuhgalter"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("Комментарий для бухгалтера")])]), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "auto-grow": "",
      "label": "Комментарий"
    },
    model: {
      value: _vm.form.commentForBuhgalter,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "commentForBuhgalter", $$v);
      },
      expression: "form.commentForBuhgalter"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog.commentForBuhgalter = false;
      }
    }
  }, [_vm._v("Готово")])], 1)], 1)], 1), _c('Loading', {
    attrs: {
      "value": _vm.fullPageLoading
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }