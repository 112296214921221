var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "500px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "color": "green"
          }
        }, on), [_vm._v(" Добавить оплату ")])];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("Добавить оплату")])]), _c('v-card-text', [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', [_c('v-col', [_c('v-col', [_c('v-dialog', {
    ref: "dateDialog",
    attrs: {
      "return-value": _vm.form.date,
      "persistent": "",
      "width": "290px"
    },
    on: {
      "update:returnValue": function updateReturnValue($event) {
        return _vm.$set(_vm.form, "date", $event);
      },
      "update:return-value": function updateReturnValue($event) {
        return _vm.$set(_vm.form, "date", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "Дата",
            "prepend-icon": "mdi-calendar-month-outline",
            "readonly": ""
          },
          model: {
            value: _vm.form.date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "date", $$v);
            },
            expression: "form.date"
          }
        }, on))];
      }
    }]),
    model: {
      value: _vm.dateDialog,
      callback: function callback($$v) {
        _vm.dateDialog = $$v;
      },
      expression: "dateDialog"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "type": "date",
      "scrollable": "",
      "first-day-of-week": "1"
    },
    model: {
      value: _vm.form.date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "date", $$v);
      },
      expression: "form.date"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.dateDialog = false;
      }
    }
  }, [_vm._v("Отмена")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.dateDialog.save(_vm.form.date);
      }
    }
  }, [_vm._v(" OK ")])], 1)], 1), _vm.form.errors.has('date') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('date')) + " ")]) : _vm._e()], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.page.lawyers,
      "label": "Юрист",
      "item-value": "id",
      "item-text": "fullName",
      "clearable": false
    },
    model: {
      value: _vm.form.lawyer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lawyer", $$v);
      },
      expression: "form.lawyer"
    }
  }), _vm.form.errors.has('lawyer') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('lawyer')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Сумма"
    },
    on: {
      "change": _vm.checkIfReturn
    },
    model: {
      value: _vm.form.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "amount", $$v);
      },
      expression: "form.amount"
    }
  }), _vm.form.errors.has('amount') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('amount')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.page.paymentTypes,
      "label": "Вид оплаты"
    },
    on: {
      "change": _vm.checkIfReturn
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _vm.form.errors.has('type') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('type')) + " ")]) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "chekbox__blue",
    attrs: {
      "label": "Бухгалтер"
    },
    model: {
      value: _vm.form.isSentForBuhgalter,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isSentForBuhgalter", $$v);
      },
      expression: "form.isSentForBuhgalter"
    }
  })], 1)], 1), _vm.form.type === 'TRANSPORT' ? _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "label": "Прочие Д/С",
      "items": _vm.page.additionalExpensesType,
      "return-object": "",
      "item-value": "value",
      "item-text": "text"
    },
    on: {
      "change": _vm.checkIfReturn
    },
    model: {
      value: _vm.form.additionalExpensesType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "additionalExpensesType", $$v);
      },
      expression: "form.additionalExpensesType"
    }
  }), _vm.form.errors.has('additionalExpensesType') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('additionalExpensesType')) + " ")]) : _vm._e()], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "label": "Комментарий",
      "rows": "2"
    },
    model: {
      value: _vm.form.comment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  }), _vm.form.errors.has('comment') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('comment')) + " ")]) : _vm._e()], 1)], 1), _vm.form.isSentForBuhgalter === true ? _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "label": "Комментарий для бухгалтера",
      "rows": "2"
    },
    model: {
      value: _vm.form.commentForBuhgalter,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "commentForBuhgalter", $$v);
      },
      expression: "form.commentForBuhgalter"
    }
  }), _vm.form.errors.has('commentForBuhgalter') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('commentForBuhgalter')) + " ")]) : _vm._e()], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "chekbox__blue",
    attrs: {
      "label": "Б/н"
    },
    on: {
      "change": function change($event) {
        _vm.form.bezNalType = null;
      }
    },
    model: {
      value: _vm.form.isBezNal,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isBezNal", $$v);
      },
      expression: "form.isBezNal"
    }
  })], 1), _vm.form.isBezNal === true ? _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.page.bezNalTypes,
      "rules": [function (v) {
        return !!v || 'Поле обязательно';
      }],
      "label": "Вид б/н"
    },
    model: {
      value: _vm.form.bezNalType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bezNalType", $$v);
      },
      expression: "form.bezNalType"
    }
  }), _vm.form.errors.has('bezNalType') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('bezNalType')) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.form.isBezNal !== true ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      "color": "green",
      "outlined": "",
      "dark": ""
    }
  }, [_c('v-checkbox', {
    staticClass: "chekbox__green",
    attrs: {
      "value": "CARD",
      "label": "Наличные (карта)"
    },
    model: {
      value: _vm.form.nalType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nalType", $$v);
      },
      expression: "form.nalType"
    }
  })], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("Отмена")]), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Сохранить")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }