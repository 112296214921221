var render = function () {
  var _vm$form$type, _vm$form$type2, _vm$form$type3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "500px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "color": "green"
          }
        }, on), [_vm._v(" Добавить акт/выплату ")])];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("Добавить акт/выплату")])]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "label": "Тип",
      "items": _vm.actTypes,
      "clearable": "",
      "return-object": "",
      "item-value": "value",
      "item-text": "text"
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _vm.form.errors.has('type') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('type')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Номер"
    },
    model: {
      value: _vm.form.number,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "number", $$v);
      },
      expression: "form.number"
    }
  }), _vm.form.errors.has('number') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('number')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Сумма"
    },
    model: {
      value: _vm.form.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "amount", $$v);
      },
      expression: "form.amount"
    }
  }), _vm.form.errors.has('amount') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('amount')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "label": "Юрист/доп. юрист",
      "items": _vm.lawyers,
      "clearable": "",
      "item-value": "id",
      "item-text": "fullName"
    },
    model: {
      value: _vm.form.lawyer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lawyer", $$v);
      },
      expression: "form.lawyer"
    }
  }), _vm.form.errors.has('lawyer') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('lawyer')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "label": "Юрист ЭПО",
      "items": _vm.lawyersEPO,
      "clearable": "",
      "item-value": "id",
      "item-text": "fullName"
    },
    model: {
      value: _vm.form.lawyerEPO,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lawyerEPO", $$v);
      },
      expression: "form.lawyerEPO"
    }
  }), _vm.form.errors.has('lawyerEPO') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('lawyerEPO')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "label": "Юрист ОГПиА",
      "items": _vm.lawyersOGPA,
      "clearable": "",
      "item-value": "id",
      "item-text": "fullName"
    },
    model: {
      value: _vm.form.lawyerOGPA,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lawyerOGPA", $$v);
      },
      expression: "form.lawyerOGPA"
    }
  }), _vm.form.errors.has('lawyerOGPA') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('lawyerOGPA')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "label": "Помощник юриста ОГПиА",
      "items": _vm.lawyersOGPA,
      "clearable": "",
      "item-value": "id",
      "item-text": "fullName"
    },
    model: {
      value: _vm.form.lawyerOGPAAssistant,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lawyerOGPAAssistant", $$v);
      },
      expression: "form.lawyerOGPAAssistant"
    }
  }), _vm.form.errors.has('lawyerOGPAAssistant') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('lawyerOGPAAssistant')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-dialog', {
    ref: "signDateDialog",
    attrs: {
      "return-value": _vm.form.signDate,
      "persistent": "",
      "width": "290px"
    },
    on: {
      "update:returnValue": function updateReturnValue($event) {
        return _vm.$set(_vm.form, "signDate", $event);
      },
      "update:return-value": function updateReturnValue($event) {
        return _vm.$set(_vm.form, "signDate", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "Дата подписания",
            "prepend-icon": "mdi-calendar-month-outline",
            "readonly": ""
          },
          model: {
            value: _vm.form.signDate,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "signDate", $$v);
            },
            expression: "form.signDate"
          }
        }, on))];
      }
    }]),
    model: {
      value: _vm.signDateDialog,
      callback: function callback($$v) {
        _vm.signDateDialog = $$v;
      },
      expression: "signDateDialog"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "type": "date",
      "scrollable": "",
      "first-day-of-week": "1"
    },
    model: {
      value: _vm.form.signDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "signDate", $$v);
      },
      expression: "form.signDate"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.signDateDialog = false;
      }
    }
  }, [_vm._v("Отмена")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.signDateDialog.save(_vm.form.signDate);
      }
    }
  }, [_vm._v(" OK ")])], 1)], 1), _vm.form.errors.has('signDate') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('signDate')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "label": "Примечание",
      "rows": "2"
    },
    model: {
      value: _vm.form.comment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  }), _vm.form.errors.has('comment') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('comment')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "auto-grow": "",
      "label": "Комментарий"
    },
    model: {
      value: _vm.form.text,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "text", $$v);
      },
      expression: "form.text"
    }
  }), _vm.form.errors.has('text') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('text')) + " ")]) : _vm._e()], 1)], 1), ((_vm$form$type = _vm.form.type) === null || _vm$form$type === void 0 ? void 0 : _vm$form$type.value) === 'ACT' ? _c('v-row', {
    staticStyle: {
      "margin-bottom": "-40px"
    }
  }, [_c('v-col', [_c('v-checkbox', {
    staticClass: "chekbox__blue",
    attrs: {
      "label": "НДС (5%)"
    },
    model: {
      value: _vm.form.vatIncluded,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "vatIncluded", $$v);
      },
      expression: "form.vatIncluded"
    }
  }), _vm.form.errors.has('vatIncluded') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('vatIncluded')) + " ")]) : _vm._e()], 1)], 1) : _vm._e(), ((_vm$form$type2 = _vm.form.type) === null || _vm$form$type2 === void 0 ? void 0 : _vm$form$type2.value) === 'ACT' ? _c('v-row', {
    staticStyle: {
      "margin-bottom": "-40px"
    }
  }, [_c('v-col', [_c('v-checkbox', {
    staticClass: "chekbox__blue",
    attrs: {
      "label": "Документы (-1000руб)"
    },
    model: {
      value: _vm.form.documents,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "documents", $$v);
      },
      expression: "form.documents"
    }
  }), _vm.form.errors.has('vatIncluded') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('vatIncluded')) + " ")]) : _vm._e()], 1)], 1) : _vm._e(), ((_vm$form$type3 = _vm.form.type) === null || _vm$form$type3 === void 0 ? void 0 : _vm$form$type3.value) === 'ACT' ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "chekbox__blue",
    attrs: {
      "value": "HALVA",
      "label": "Халва"
    },
    model: {
      value: _vm.form.installment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "installment", $$v);
      },
      expression: "form.installment"
    }
  }), _vm.form.errors.has('installment') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('installment')) + " ")]) : _vm._e()], 1), _vm.form.installment === 'HALVA' ? _c('v-col', [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "оплачено Халвой, руб. (-10%)"
    },
    model: {
      value: _vm.form.halvaAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "halvaAmount", $$v);
      },
      expression: "form.halvaAmount"
    }
  }), _vm.form.errors.has('halvaAmount') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('halvaAmount')) + " ")]) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("Отмена")]), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Сохранить")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }