<template>
    <v-card-text>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader type="table-row@3"></v-skeleton-loader>
            </v-col>
        </v-row>
        <div v-if="!loading" style="overflow: auto; border-left: thin solid rgba(255, 255, 255, 0.18) !important;">
            <v-data-table
                :style="`min-width: ${getTableWidth()}px`"
                :headers="headers"
                :items="page.items"
                hide-default-footer
                disable-pagination
                class="table__lined">
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td>{{item.name}}</td>
                        <td>{{item.clients}}</td>
                        <td>{{item.clientsOnline}}</td>
                        <td>
                            {{item.contracts}}
                            (<span v-html="$helper.report.getPercentageHtml(item.contracts, item.clients, $helper.report.contractStatOptions)"></span>)
                        </td>
                        <td width="140">
                            {{item.contractTypes?.PRIMARY || 0}}
                            (<span v-html="$helper.report.getPercentageHtml(item.contractTypes?.PRIMARY, item.clients, $helper.report.contractPrimaryStatOptions)"></span>)
                        </td>
                        <td width="150">
                            {{item.contractTypes?.BK || 0}}
                            (<span v-html="$helper.report.getPercentageHtml(item.contractTypes?.BK, item.contracts)"></span>)
                        </td>
                        <td width="160">
                            {{item.contractTypes?.UP_SELL || 0}}
                            (<span v-html="$helper.report.getPercentageHtml(item.contractTypes?.UP_SELL, item.contracts)"></span>)
                        </td>
                        <td width="150">
                            {{item.contractSubjects?.DOCUMENTS || 0}}
                            (<span v-html="$helper.report.getPercentageHtml(item.contractSubjects?.DOCUMENTS, item.contracts)"></span>)
                        </td>
                        <td width="120">
                            {{item.contractSubjects?.DEPARTURE || 0}}
                            (<span v-html="$helper.report.getPercentageHtml(item.contractSubjects?.DEPARTURE, item.contracts)"></span>)
                        </td>
                        <td width="120">
                            {{item.contractSubjects?.COURT || 0}}
                            (<span v-html="$helper.report.getPercentageHtml(item.contractSubjects?.COURT, item.contracts)"></span>)
                        </td>
                        <td>{{ $helper.format.currency(item.cash) }}</td>
                        <td width="140">{{ $helper.format.currency(item.halva10) }}</td>
                        <td width="160">
                            {{ $helper.format.currency(item.cleanCash) }}
                            {{ getCleanCashPercent(item.cleanCash) }}
                        </td>
                        <td width="140">
                            <span :class="$helper.report.getStatColor(item.payload, $helper.report.payloadStatOptions)">
                                {{ $helper.format.currency(item.payload) }}
                            </span>
                        </td>
                        <td>
                            <span :class="$helper.report.getStatColor($helper.report.getPairKey(item.moneyBack), $helper.report.moneyBackStatOptions)">
                                {{ $helper.format.currency($helper.report.getPairKey(item.moneyBack)) }}
                            </span>
                            <ContractLinks :contracts="$helper.report.getPairValue(item?.moneyBack)"></ContractLinks>
                        </td>
                        <td>
                            <span :class="$helper.report.getStatColor($helper.report.getPairKey(item.cancellation), $helper.report.payloadStatOptions)">
                                {{ $helper.format.currency($helper.report.getPairKey(item.cancellation)) }}
                            </span>
                            <ContractLinks :contracts="$helper.report.getPairValue(item?.cancellation)"></ContractLinks>
                        </td>
                        <td>
                            {{item.canceledClients}}
                            (<span v-html="$helper.report.getPercentageHtml(item.canceledClients, item.clients)"></span>)
                        </td>
                    </tr>
                    <tr class="result-row">
                        <td v-for="(header, i) in headers" :key="i">
                            <div v-if="header.value === 'name'">Всего:</div>
                            <div v-if="header.value === 'payload'">
                                <span :class="$helper.report.getStatColor(totalPayload, $helper.report.payloadStatOptions)">
                                    {{ $helper.format.number(totalPayload) }}
                                </span>
                            </div>
                            <div v-if="header.value === 'moneyBack'">
                                <span :class="$helper.report.getStatColor(totalForPair('moneyBack'), $helper.report.moneyBackStatOptions)">
                                    {{ $helper.format.number(totalForPair('moneyBack')) }}
                                </span>
                            </div>
                            <div v-if="header.value === 'contracts' || header.value === 'contractTypes.PRIMARY'">
                                {{ $helper.format.number(total(header.value)) }}
                                (<span
                                    v-if="header.percentTo"
                                    v-html="$helper.report.getPercentageHtml(total(header.value), total(header.percentTo), $helper.report.contractStatOptions)">
                                </span>)
                            </div>
                            <div v-if="header.value === 'cancellation'">
                                  <span :class="$helper.report.getStatColor(totalForPair('cancellation'), $helper.report.moneyBackStatOptions)">
                                      {{ $helper.format.number(totalForPair('cancellation')) }}
                                  </span>
                            </div>
                            <div v-if="i !== 0 && !isCustomTotal(header.value)">
                                {{ $helper.format.number(total(header.value)) }}
                                <span v-if="header.percentTo">
                                    (<span v-html="$helper.report.getPercentageHtml(total(header.value), total(header.percentTo))"></span>)
                                </span>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </v-card-text>
</template>

<script>

    import ReportService from "@/_services/ReportService";
    import ContractLinks from "@/reports/epo/ContractLinks.vue";

    export default {
        name: 'ReportLawyers',
        components: {ContractLinks},
        props: {
          filter: Object,
        },
        data: function() {
            return {
                loading: false,
                page: {
                    items: [],
                    contractTypes: [],
                    contractSubjects: []
                },
                headers: [
                    {text: 'ФИО Юриста', value: 'name'},
                    {text: 'Клиентов всего', value: 'clients'},
                    {text: 'Клиентов Онлайн', value: 'clientsOnline'},
                    {text: 'Договоров всего', value: 'contracts', percentTo: 'clients'},
                    {text: 'Договор первичный', value: 'contractTypes.PRIMARY', percentTo: 'clients'},
                    {text: 'Договор БК', value: 'contractTypes.BK', percentTo: 'contracts'},
                    {text: 'Договор Пролонгация', value: 'contractTypes.UP_SELL', percentTo: 'contracts'},
                    {text: 'Документы', value: 'contractSubjects.DOCUMENTS', percentTo: 'contracts'},
                    {text: 'Выезд', value: 'contractSubjects.DEPARTURE', percentTo: 'contracts'},
                    {text: 'Суд', value: 'contractSubjects.COURT', percentTo: 'contracts'},
                    {text: 'Касса', value: 'cash'},
                    {text: 'Халва 10%', value: 'halva10'},
                    {text: 'Чистая касса', value: 'cleanCash'},
                    {text: 'Нагрузка', value: 'payload'},
                    {text: 'Возврат денег', value: 'moneyBack'},
                    {text: 'Аннулирование', value: 'cancellation'},
                    {text: 'Клиент Снят', value: 'canceledClients'},
                ]
            }
        },
        watch: { },
        methods: {
            fetch() {
                ReportService.getLawyersReport(this.filter).then(page => {
                    this.page = page;
                    this.loading = false;
                });
            },
            isCustomTotal(column) {
                const columns = [
                    "payload",
                    "contracts",
                    "contractTypes.PRIMARY",
                    "payload",
                    "moneyBack",
                    "cancellation"
                ];
                return columns.includes(column);
            },
            getTableWidth() {
                return 2300;
            },
            getCleanCashPercent(cleanCash) {
                if (!cleanCash) {
                    return '';
                }
                const percents = {
                    177001: 5,
                    377001: 7,
                    517001: 9,
                    657001: 11,
                    777778: 12,
                    999779: 14,
                    99999999: 15
                }
                let percent;
                for (const amount of Object.keys(percents)) {
                    if (cleanCash < amount) {
                        percent = percents[amount];
                        break;
                    }
                }
                if (percent) {
                    return `(${percent}%)`;
                }
            }
        },
        computed: {
            totalForPair() {
                return column => this.total(column, true);
            },
            total() {
                return (column, forPair = false) => {
                    if (!column) {
                        return '';
                    }
                    let total = 0;
                    this.page.items?.forEach(item => {
                        if (column.includes('.')) {
                          const columnPath = column.split('.');
                          if (forPair) {
                            total += this.$helper.report.getPairKey(item[columnPath[0]][columnPath[1]]) || 0;
                          } else {
                            total += item[columnPath[0]][columnPath[1]] || 0;
                          }
                        } else {
                          if (forPair) {
                            total += this.$helper.report.getPairKey(item[column]) || 0;
                          } else {
                            total += item[column] || 0;
                          }
                        }
                    });
                    return total;
                }
            },
            totalPayload() {
                const clients = this.total("clients");
                const cash = this.total("cleanCash");
                return cash == 0 ? 0 : (cash / clients);
            }
        },
        created() {
            this.loading = true;
            this.fetch();
        },
        beforeCreate() {

        }
    };
</script>