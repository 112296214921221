var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card-text', [_vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "table-row@3"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('div', {
    staticStyle: {
      "overflow": "auto",
      "border-left": "thin solid rgba(255, 255, 255, 0.18) !important"
    }
  }, [_c('v-data-table', {
    staticClass: "table__lined",
    style: "min-width: ".concat(_vm.getTableWidth(), "px"),
    attrs: {
      "headers": _vm.headers,
      "items": _vm.page.items,
      "hide-default-footer": "",
      "disable-pagination": ""
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [_c('tbody', [_vm._l(items, function (item) {
          var _item$contractTypes, _item$contractTypes2, _item$contractTypes3, _item$contractTypes4, _item$contractTypes5, _item$contractTypes6, _item$contractSubject, _item$contractSubject2, _item$contractSubject3, _item$contractSubject4, _item$contractSubject5, _item$contractSubject6;
          return _c('tr', {
            key: item.id
          }, [_c('td', [_vm._v(_vm._s(item.name))]), _c('td', [_vm._v(_vm._s(item.clients))]), _c('td', [_vm._v(_vm._s(item.clientsOnline))]), _c('td', [_vm._v(" " + _vm._s(item.contracts) + " ("), _c('span', {
            domProps: {
              "innerHTML": _vm._s(_vm.$helper.report.getPercentageHtml(item.contracts, item.clients, _vm.$helper.report.contractStatOptions))
            }
          }), _vm._v(") ")]), _c('td', {
            attrs: {
              "width": "140"
            }
          }, [_vm._v(" " + _vm._s(((_item$contractTypes = item.contractTypes) === null || _item$contractTypes === void 0 ? void 0 : _item$contractTypes.PRIMARY) || 0) + " ("), _c('span', {
            domProps: {
              "innerHTML": _vm._s(_vm.$helper.report.getPercentageHtml((_item$contractTypes2 = item.contractTypes) === null || _item$contractTypes2 === void 0 ? void 0 : _item$contractTypes2.PRIMARY, item.clients, _vm.$helper.report.contractPrimaryStatOptions))
            }
          }), _vm._v(") ")]), _c('td', {
            attrs: {
              "width": "150"
            }
          }, [_vm._v(" " + _vm._s(((_item$contractTypes3 = item.contractTypes) === null || _item$contractTypes3 === void 0 ? void 0 : _item$contractTypes3.BK) || 0) + " ("), _c('span', {
            domProps: {
              "innerHTML": _vm._s(_vm.$helper.report.getPercentageHtml((_item$contractTypes4 = item.contractTypes) === null || _item$contractTypes4 === void 0 ? void 0 : _item$contractTypes4.BK, item.contracts))
            }
          }), _vm._v(") ")]), _c('td', {
            attrs: {
              "width": "160"
            }
          }, [_vm._v(" " + _vm._s(((_item$contractTypes5 = item.contractTypes) === null || _item$contractTypes5 === void 0 ? void 0 : _item$contractTypes5.UP_SELL) || 0) + " ("), _c('span', {
            domProps: {
              "innerHTML": _vm._s(_vm.$helper.report.getPercentageHtml((_item$contractTypes6 = item.contractTypes) === null || _item$contractTypes6 === void 0 ? void 0 : _item$contractTypes6.UP_SELL, item.contracts))
            }
          }), _vm._v(") ")]), _c('td', {
            attrs: {
              "width": "150"
            }
          }, [_vm._v(" " + _vm._s(((_item$contractSubject = item.contractSubjects) === null || _item$contractSubject === void 0 ? void 0 : _item$contractSubject.DOCUMENTS) || 0) + " ("), _c('span', {
            domProps: {
              "innerHTML": _vm._s(_vm.$helper.report.getPercentageHtml((_item$contractSubject2 = item.contractSubjects) === null || _item$contractSubject2 === void 0 ? void 0 : _item$contractSubject2.DOCUMENTS, item.contracts))
            }
          }), _vm._v(") ")]), _c('td', {
            attrs: {
              "width": "120"
            }
          }, [_vm._v(" " + _vm._s(((_item$contractSubject3 = item.contractSubjects) === null || _item$contractSubject3 === void 0 ? void 0 : _item$contractSubject3.DEPARTURE) || 0) + " ("), _c('span', {
            domProps: {
              "innerHTML": _vm._s(_vm.$helper.report.getPercentageHtml((_item$contractSubject4 = item.contractSubjects) === null || _item$contractSubject4 === void 0 ? void 0 : _item$contractSubject4.DEPARTURE, item.contracts))
            }
          }), _vm._v(") ")]), _c('td', {
            attrs: {
              "width": "120"
            }
          }, [_vm._v(" " + _vm._s(((_item$contractSubject5 = item.contractSubjects) === null || _item$contractSubject5 === void 0 ? void 0 : _item$contractSubject5.COURT) || 0) + " ("), _c('span', {
            domProps: {
              "innerHTML": _vm._s(_vm.$helper.report.getPercentageHtml((_item$contractSubject6 = item.contractSubjects) === null || _item$contractSubject6 === void 0 ? void 0 : _item$contractSubject6.COURT, item.contracts))
            }
          }), _vm._v(") ")]), _c('td', [_vm._v(_vm._s(_vm.$helper.format.currency(item.cash)))]), _c('td', {
            attrs: {
              "width": "140"
            }
          }, [_vm._v(_vm._s(_vm.$helper.format.currency(item.halva10)))]), _c('td', {
            attrs: {
              "width": "160"
            }
          }, [_vm._v(" " + _vm._s(_vm.$helper.format.currency(item.cleanCash)) + " " + _vm._s(_vm.getCleanCashPercent(item.cleanCash)) + " ")]), _c('td', {
            attrs: {
              "width": "140"
            }
          }, [_c('span', {
            class: _vm.$helper.report.getStatColor(item.payload, _vm.$helper.report.payloadStatOptions)
          }, [_vm._v(" " + _vm._s(_vm.$helper.format.currency(item.payload)) + " ")])]), _c('td', [_c('span', {
            class: _vm.$helper.report.getStatColor(_vm.$helper.report.getPairKey(item.moneyBack), _vm.$helper.report.moneyBackStatOptions)
          }, [_vm._v(" " + _vm._s(_vm.$helper.format.currency(_vm.$helper.report.getPairKey(item.moneyBack))) + " ")]), _c('ContractLinks', {
            attrs: {
              "contracts": _vm.$helper.report.getPairValue(item === null || item === void 0 ? void 0 : item.moneyBack)
            }
          })], 1), _c('td', [_c('span', {
            class: _vm.$helper.report.getStatColor(_vm.$helper.report.getPairKey(item.cancellation), _vm.$helper.report.payloadStatOptions)
          }, [_vm._v(" " + _vm._s(_vm.$helper.format.currency(_vm.$helper.report.getPairKey(item.cancellation))) + " ")]), _c('ContractLinks', {
            attrs: {
              "contracts": _vm.$helper.report.getPairValue(item === null || item === void 0 ? void 0 : item.cancellation)
            }
          })], 1), _c('td', [_vm._v(" " + _vm._s(item.canceledClients) + " ("), _c('span', {
            domProps: {
              "innerHTML": _vm._s(_vm.$helper.report.getPercentageHtml(item.canceledClients, item.clients))
            }
          }), _vm._v(") ")])]);
        }), _c('tr', {
          staticClass: "result-row"
        }, _vm._l(_vm.headers, function (header, i) {
          return _c('td', {
            key: i
          }, [header.value === 'name' ? _c('div', [_vm._v("Всего:")]) : _vm._e(), header.value === 'payload' ? _c('div', [_c('span', {
            class: _vm.$helper.report.getStatColor(_vm.totalPayload, _vm.$helper.report.payloadStatOptions)
          }, [_vm._v(" " + _vm._s(_vm.$helper.format.number(_vm.totalPayload)) + " ")])]) : _vm._e(), header.value === 'moneyBack' ? _c('div', [_c('span', {
            class: _vm.$helper.report.getStatColor(_vm.totalForPair('moneyBack'), _vm.$helper.report.moneyBackStatOptions)
          }, [_vm._v(" " + _vm._s(_vm.$helper.format.number(_vm.totalForPair('moneyBack'))) + " ")])]) : _vm._e(), header.value === 'contracts' || header.value === 'contractTypes.PRIMARY' ? _c('div', [_vm._v(" " + _vm._s(_vm.$helper.format.number(_vm.total(header.value))) + " ("), header.percentTo ? _c('span', {
            domProps: {
              "innerHTML": _vm._s(_vm.$helper.report.getPercentageHtml(_vm.total(header.value), _vm.total(header.percentTo), _vm.$helper.report.contractStatOptions))
            }
          }) : _vm._e(), _vm._v(") ")]) : _vm._e(), header.value === 'cancellation' ? _c('div', [_c('span', {
            class: _vm.$helper.report.getStatColor(_vm.totalForPair('cancellation'), _vm.$helper.report.moneyBackStatOptions)
          }, [_vm._v(" " + _vm._s(_vm.$helper.format.number(_vm.totalForPair('cancellation'))) + " ")])]) : _vm._e(), i !== 0 && !_vm.isCustomTotal(header.value) ? _c('div', [_vm._v(" " + _vm._s(_vm.$helper.format.number(_vm.total(header.value))) + " "), header.percentTo ? _c('span', [_vm._v(" ("), _c('span', {
            domProps: {
              "innerHTML": _vm._s(_vm.$helper.report.getPercentageHtml(_vm.total(header.value), _vm.total(header.percentTo)))
            }
          }), _vm._v(") ")]) : _vm._e()]) : _vm._e()]);
        }), 0)], 2)];
      }
    }], null, false, 2599198986)
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }