var render = function () {
  var _vm$form$contract, _vm$form$type;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, table-row@3, button"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('v-card', [_c('v-card-text', [_c('form', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "9"
    }
  }, [_vm.act !== null ? _c('v-row', [_vm.form.id ? _c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.form.id,
      "disabled": "disabled",
      "label": "ID"
    }
  })], 1) : _vm._e(), _c('v-col', [_c('v-select', {
    attrs: {
      "label": "Тип",
      "items": _vm.actTypes,
      "clearable": "",
      "return-object": "",
      "item-value": "value",
      "item-text": "text"
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  })], 1), _c('v-col', [_c('DateField', {
    attrs: {
      "label": "Дата",
      "disabled": !_vm.$helper.permission.canCreateAct(_vm.user)
    },
    model: {
      value: _vm.form.date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "date", $$v);
      },
      expression: "form.date"
    }
  })], 1), _c('v-col', [_c('v-dialog', {
    ref: "signDateDialog",
    attrs: {
      "return-value": _vm.form.signDate,
      "persistent": "",
      "width": "290px"
    },
    on: {
      "update:returnValue": function updateReturnValue($event) {
        return _vm.$set(_vm.form, "signDate", $event);
      },
      "update:return-value": function updateReturnValue($event) {
        return _vm.$set(_vm.form, "signDate", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "Дата подписания",
            "prepend-icon": "mdi-calendar-month-outline",
            "readonly": ""
          },
          model: {
            value: _vm.form.signDate,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "signDate", $$v);
            },
            expression: "form.signDate"
          }
        }, on))];
      }
    }], null, false, 965838010),
    model: {
      value: _vm.signDateDialog,
      callback: function callback($$v) {
        _vm.signDateDialog = $$v;
      },
      expression: "signDateDialog"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "type": "date",
      "scrollable": "",
      "first-day-of-week": "1"
    },
    model: {
      value: _vm.form.signDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "signDate", $$v);
      },
      expression: "form.signDate"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.signDateDialog = false;
      }
    }
  }, [_vm._v("Отмена")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.signDateDialog.save(_vm.form.signDate);
      }
    }
  }, [_vm._v(" OK ")])], 1)], 1), _vm.form.errors.has('signDate') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(_vm._s(_vm.form.errors.get('signDate')) + " ")]) : _vm._e()], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Номер"
    },
    model: {
      value: _vm.form.number,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "number", $$v);
      },
      expression: "form.number"
    }
  }), _vm.form.errors.has('number') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('number')) + " ")]) : _vm._e()], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Сумма акта"
    },
    model: {
      value: _vm.form.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "amount", $$v);
      },
      expression: "form.amount"
    }
  }), _vm.form.errors.has('amount') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('amount')) + " ")]) : _vm._e()], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "disabled": "",
      "label": "Чистая сумма"
    },
    model: {
      value: _vm.form.cleanAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cleanAmount", $$v);
      },
      expression: "form.cleanAmount"
    }
  })], 1), _vm.form.contract ? _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Договор",
      "disabled": "disabled"
    },
    model: {
      value: _vm.form.contract.number,
      callback: function callback($$v) {
        _vm.$set(_vm.form.contract, "number", $$v);
      },
      expression: "form.contract.number"
    }
  })], 1) : _vm._e(), _vm.$_.get(_vm.page, 'act.contract') ? _c('v-col', [_c('v-btn', {
    attrs: {
      "to": "/contracts/".concat(_vm.page.act.contract.id, "/edit"),
      "small": "",
      "outlined": "",
      "color": "green"
    }
  }, [_vm._v(" Перейти ")])], 1) : _vm._e()], 1), _c('v-row', [_vm.form.contract ? _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.lawyers,
      "label": "Юрист (по договору)",
      "item-value": "id",
      "item-text": "fullName",
      "disabled": "disabled",
      "clearable": true
    },
    model: {
      value: _vm.form.contract.lawyer,
      callback: function callback($$v) {
        _vm.$set(_vm.form.contract, "lawyer", $$v);
      },
      expression: "form.contract.lawyer"
    }
  })], 1) : _vm._e(), _vm.form.contract ? _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.lawyersOGPA,
      "label": "Юрист ОГПиА (по договору)",
      "item-value": "id",
      "item-text": "fullName",
      "disabled": "disabled",
      "clearable": true
    },
    model: {
      value: _vm.form.contract.lawyerOGPA,
      callback: function callback($$v) {
        _vm.$set(_vm.form.contract, "lawyerOGPA", $$v);
      },
      expression: "form.contract.lawyerOGPA"
    }
  })], 1) : _vm._e(), _vm.$_.get(_vm.page, 'act.createdBy') ? _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Создал",
      "disabled": "disabled"
    },
    model: {
      value: _vm.form.createdBy.fullName,
      callback: function callback($$v) {
        _vm.$set(_vm.form.createdBy, "fullName", $$v);
      },
      expression: "form.createdBy.fullName"
    }
  })], 1) : _vm._e()], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.lawyersEPO,
      "label": "Юрист ЭПО (по акту)",
      "item-value": "id",
      "item-text": "fullName",
      "clearable": true
    },
    model: {
      value: _vm.form.lawyerEPO,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lawyerEPO", $$v);
      },
      expression: "form.lawyerEPO"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.lawyersOGPA,
      "label": "Юрист ОГПиА (по акту)",
      "item-value": "id",
      "item-text": "fullName",
      "clearable": true
    },
    model: {
      value: _vm.form.lawyerOGPA,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lawyerOGPA", $$v);
      },
      expression: "form.lawyerOGPA"
    }
  })], 1)], 1), _c('v-row', [_c('v-col'), _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.lawyersOGPA,
      "label": "Помощник юриста ОГПиА (по акту)",
      "item-value": "id",
      "item-text": "fullName",
      "clearable": true
    },
    model: {
      value: _vm.form.lawyerOGPAAssistant,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lawyerOGPAAssistant", $$v);
      },
      expression: "form.lawyerOGPAAssistant"
    }
  })], 1)], 1), (_vm$form$contract = _vm.form.contract) !== null && _vm$form$contract !== void 0 && _vm$form$contract.client ? _c('v-row', [_c('v-col', [_vm.form.contract.client ? _c('v-text-field', {
    attrs: {
      "label": "Клиент",
      "disabled": "disabled"
    },
    model: {
      value: _vm.form.contract.client.userName,
      callback: function callback($$v) {
        _vm.$set(_vm.form.contract.client, "userName", $$v);
      },
      expression: "form.contract.client.userName"
    }
  }) : _vm._e()], 1), _vm.$_.get(_vm.page, 'act.contract.client.id') ? _c('v-col', [_c('v-btn', {
    attrs: {
      "to": "/clients/".concat(_vm.page.act.contract.client.id, "/edit"),
      "small": "",
      "outlined": "",
      "color": "green"
    }
  }, [_vm._v(" Перейти ")])], 1) : _vm._e()], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "label": "Примечание",
      "rows": "2"
    },
    model: {
      value: _vm.form.comment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  }), _vm.form.errors.has('comment') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('comment')) + " ")]) : _vm._e()], 1), ((_vm$form$type = _vm.form.type) === null || _vm$form$type === void 0 ? void 0 : _vm$form$type.value) === 'ACT' ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticStyle: {
      "margin-bottom": "-50px"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "chekbox__blue",
    attrs: {
      "label": "НДС (5%)"
    },
    model: {
      value: _vm.form.vatIncluded,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "vatIncluded", $$v);
      },
      expression: "form.vatIncluded"
    }
  }), _vm.form.errors.has('vatIncluded') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('vatIncluded')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "chekbox__blue",
    attrs: {
      "label": "Документы (-1000руб)"
    },
    model: {
      value: _vm.form.documents,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "documents", $$v);
      },
      expression: "form.documents"
    }
  }), _vm.form.errors.has('vatIncluded') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('vatIncluded')) + " ")]) : _vm._e()], 1)], 1)], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "chekbox__blue",
    attrs: {
      "value": "HALVA",
      "label": "Халва"
    },
    model: {
      value: _vm.form.installment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "installment", $$v);
      },
      expression: "form.installment"
    }
  }), _vm.form.errors.has('installment') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('installment')) + " ")]) : _vm._e()], 1), _vm.form.installment === 'HALVA' ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "оплачено Халвой, руб. (-10%)"
    },
    model: {
      value: _vm.form.halvaAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "halvaAmount", $$v);
      },
      expression: "form.halvaAmount"
    }
  }), _vm.form.errors.has('halvaAmount') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('halvaAmount')) + " ")]) : _vm._e()], 1) : _vm._e()], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "3"
    }
  })], 1), _vm.form.errors.any() ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" Исправьте ошибки ")]) : _vm._e(), _vm.form.errors.has('error') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('error')) + " ")]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.form.busy,
      expression: "!form.busy"
    }]
  }, [_vm.$helper.permission.canCreateAct(_vm.user) ? _c('v-btn', {
    attrs: {
      "large": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        _vm.act && _vm.act.id ? _vm.update(false) : _vm.save(false);
      }
    }
  }, [_vm._v(" Сохранить ")]) : _vm._e(), _vm.$helper.permission.canCreateAct(_vm.user) ? _c('v-btn', {
    staticClass: "ml-4",
    attrs: {
      "large": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        _vm.act && _vm.act.id ? _vm.update(true) : _vm.save(false);
      }
    }
  }, [_vm._v(" Сохранить и закрыть ")]) : _vm._e()], 1), _c('v-progress-circular', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.busy,
      expression: "form.busy"
    }],
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  })], 1)])], 1) : _vm._e(), _vm.act && _vm.act.id ? _c('ActComments', {
    attrs: {
      "id": _vm.act.id
    }
  }) : _vm._e(), _vm.act && _vm.act.id ? _c('NotificationsGrid', {
    attrs: {
      "id": _vm.act.id,
      "type": "Act"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }