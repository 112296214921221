var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-expansion-panels', [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('v-card-title', [_vm._v("История изменений")])], 1), _c('v-expansion-panel-content', [_c('div', {
    staticStyle: {
      "overflow-x": "scroll",
      "overflow-y": "auto"
    }
  }, [_c('v-data-table', {
    staticClass: "table__framed",
    style: "min-width: 3850px",
    attrs: {
      "headers": _vm.headers,
      "fixed-header": "",
      "items": _vm.contracts,
      "options": _vm.contractGridsOptions.options,
      "server-items-length": _vm.totalElements,
      "item-key": "id",
      "disable-sort": true,
      "loading": _vm.loading,
      "loading-text": "Загрузка ...",
      "footer-props": {
        'items-per-page-options': [10, 20, 30, 100, 500]
      },
      "height": _vm.tableHeight
    },
    on: {
      "update:options": function updateOptions($event) {
        return _vm.$set(_vm.contractGridsOptions, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [_c('tbody', _vm._l(items, function (item, key) {
          return _c('tr', {
            key: item.id
          }, [_c('td', {
            attrs: {
              "width": "150"
            }
          }, [item.modifiedAt ? _c('div', [_vm._v(" " + _vm._s(_vm.$moment(item.modifiedAt).dateTimeFormat()) + " ")]) : _vm._e()]), _c('td', {
            attrs: {
              "width": "200"
            }
          }, [_c('div', [_vm._v(_vm._s(item.modifiedBy))])]), _c('td', [item.number ? _c('div', [_vm._v(" " + _vm._s(item.number) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'number'))
            }
          })]), _c('td', [item.state ? _c('v-chip', {
            attrs: {
              "color": item.state.chipColor,
              "outlined": "",
              "dark": ""
            }
          }, [_vm._v(" " + _vm._s(item.state.text) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'state', function (field) {
                return field.text;
              }))
            }
          })], 1), _c('td', [item.phase ? _c('v-chip', {
            attrs: {
              "color": item.phase.chipColor,
              "outlined": "",
              "dark": ""
            }
          }, [_vm._v(" " + _vm._s(item.phase.text) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'phase', function (field) {
                return field.text;
              }))
            }
          })], 1), _c('td', [item.userName ? _c('div', [_vm._v(" " + _vm._s(item.userName) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'userName'))
            }
          })]), _c('td', [item.principal ? _c('div', [_vm._v(" " + _vm._s(item.principal) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'principal'))
            }
          })]), _c('td', [item.clientEmail ? _c('div', [_vm._v(" " + _vm._s(item.clientEmail) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'clientEmail'))
            }
          })]), _c('td', [item.lawyer ? _c('div', [_vm._v(" " + _vm._s(item.lawyer.fullName) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'lawyer', function (lawyer) {
                return lawyer === null || lawyer === void 0 ? void 0 : lawyer.fullName;
              }))
            }
          })]), _c('td', [item.lawyerEPO ? _c('div', [_vm._v(" " + _vm._s(item.lawyerEPO.fullName) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'lawyerEPO', function (lawyer) {
                return lawyer === null || lawyer === void 0 ? void 0 : lawyer.fullName;
              }))
            }
          })]), _c('td', {
            attrs: {
              "width": "200"
            }
          }, [item.lawyerOGPA ? _c('div', [_vm._v(" " + _vm._s(item.lawyerOGPA.fullName) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'lawyerOGPA', function (lawyer) {
                return lawyer === null || lawyer === void 0 ? void 0 : lawyer.fullName;
              }))
            }
          })]), _c('td', [item.isPaymentOGPA ? _c('div', [_vm._v(" " + _vm._s(_vm.$helper.showBooleanValue(item.isPaymentOGPA)) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'isPaymentOGPA'))
            }
          })]), _c('td', [item.isEmployeeControl ? _c('div', [_vm._v(" " + _vm._s(_vm.$helper.showBooleanValue(item.isEmployeeControl)) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'isEmployeeControl'))
            }
          })]), _c('td', [_vm._v(" " + _vm._s(_vm.$moment(item.createdAt).dateTimeFormat()) + " ")]), _c('td', [item.amount ? _c('div', [_vm._v(" " + _vm._s(item.amount) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'amount'))
            }
          })]), _c('td', [item.type ? _c('div', [_vm._v(" " + _vm._s(_vm.getTypeName(item.type)) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'type', function (type) {
                return _vm.getTypeName(type);
              }))
            }
          })]), _c('td', [item.subject ? _c('div', [_vm._v(" " + _vm._s(_vm.getSubjectName(item.subject)) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'subject', function (subject) {
                return _vm.getSubjectName(subject);
              }))
            }
          })]), _c('td', [item.actions ? _c('div', [_vm._v(" " + _vm._s(item.actions) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'actions'))
            }
          })]), _c('td', [item.additionalLawyer ? _c('div', [_vm._v(" " + _vm._s(item.additionalLawyer.fullName) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'additionalLawyer', function (lawyer) {
                return lawyer === null || lawyer === void 0 ? void 0 : lawyer.fullName;
              }))
            }
          })]), _c('td', {
            attrs: {
              "width": "200"
            }
          }, [item.additionalLawyerOGPA ? _c('div', [_vm._v(" " + _vm._s(item.additionalLawyerOGPA.fullName) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'additionalLawyerOGPA', function (lawyer) {
                return lawyer === null || lawyer === void 0 ? void 0 : lawyer.fullName;
              }))
            }
          })]), _c('td', [item.lawyerAssistant ? _c('div', [_vm._v(" " + _vm._s(item.lawyerAssistant.fullName) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'lawyerAssistant', function (lawyer) {
                return lawyer === null || lawyer === void 0 ? void 0 : lawyer.fullName;
              }))
            }
          })]), _c('td', {
            attrs: {
              "width": "200"
            }
          }, [item.lawyerOGPAAssistant ? _c('div', [_vm._v(" " + _vm._s(item.lawyerOGPAAssistant.fullName) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'lawyerOGPAAssistant', function (lawyer) {
                return lawyer === null || lawyer === void 0 ? void 0 : lawyer.fullName;
              }))
            }
          })]), _c('td', [item.additionalLawyerEPO ? _c('div', [_vm._v(" " + _vm._s(item.additionalLawyerEPO.fullName) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'additionalLawyerEPO', function (lawyer) {
                return lawyer === null || lawyer === void 0 ? void 0 : lawyer.fullName;
              }))
            }
          })]), _c('td', [_vm._v(" " + _vm._s(_vm.$moment(item.courtDateTime).dateFormat()) + " "), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'courtDateTime', function (time) {
                return _vm.$moment(time).dateFormat();
              }))
            }
          })]), _c('td', [item.applicationDate ? _c('div', [_vm._v(" " + _vm._s(_vm.$moment(item.applicationDate).dateFormat()) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'applicationDate', function (time) {
                return _vm.$moment(time).dateFormat();
              }))
            }
          })]), _c('td', [item.answerDate ? _c('div', [_vm._v(" " + _vm._s(_vm.$moment(item.answerDate).dateFormat()) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'answerDate', function (time) {
                return _vm.$moment(time).dateFormat();
              }))
            }
          })]), _c('td', [item.moneyBackDate ? _c('div', [_vm._v(" " + _vm._s(_vm.$moment(item.moneyBackDate).dateFormat()) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'moneyBackDate', function (time) {
                return _vm.$moment(time).dateFormat();
              }))
            }
          })]), _c('td', [item.area ? _c('div', [_vm._v(" " + _vm._s(item.area.name) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'area', function (area) {
                return area === null || area === void 0 ? void 0 : area.name;
              }))
            }
          })]), _c('td', [item.comment ? _c('div', [_vm._v(" " + _vm._s(item.comment) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'comment'))
            }
          })]), _c('td', [item.office ? _c('div', [_vm._v(" " + _vm._s(item.office.name) + " ")]) : _vm._e(), _c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.showChange(item, 'office', function (office) {
                return office === null || office === void 0 ? void 0 : office.name;
              }))
            }
          })])]);
        }), 0)];
      }
    }])
  }, [_vm._v("> ")])], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }